/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:58:43
 * @LastEditors: ouyaping
 * @LastEditTime: 2025-02-21 18:13:25
 */
import arLocale from 'element-ui/lib/locale/lang/ar'
import agrEn from './agr-en'
export default {
    // ai报告相关
    ai: {
        report: "تحليل تقرير AI",
        train: "خطة التدريب",
        loading: "جاري التحميل...",
        loadingError1: "تعذر التحميل",
        loadingError2: "عذراً، حاول مرة أخرى",
    },
    // 登录页面
    login: {
        loginAndRegister: 'التسجيل / تسجيل الدخول',
        registerAndLogin: 'تسجيل الدخول/تسجيل',
        userLogin: 'تسجيل دخول المستخدم',
        title: 'حسابات الهاتف والبريد الإلكتروني مستقلة عن الآخر، يرجى ملاحظة نوع حسابك.',
        mobileLogin: 'الهاتف',
        emailLogin: 'البريد الإلكتروني',
        email: 'البريد الإلكتروني',
        loginBtn: 'تسجيل الدخول',
        autoLogin: 'تسجيل الدخول تلقائيا في المرة القادمة',
        retransmission: 'إعادة الإرسال بعد ثوان',
        formList: [
            {
                label: 'يرجى إدخال رقم هاتفك المحمول',
                placeholder: 'الحصول على رمز التحقق',
            },
            {
                label: 'رمز التحقق',
                placeholder: 'يرجى إدخال رمز التحقق',
            },
            {
                label: 'يرجى إدخال البريد الإلكتروني',
            },
        ],
        btn: {
            submit: 'إنشاء حساب جديد',
            title: 'المعلومات الأصلية مهمة جدا، يرجى تعبئتها بعناية',
        },
        rule: {
            phone: {
                requiredMsg: 'صيغة رقم الهاتف المحمول غير صحيحة',
                patternMsg: 'لم يكن رقم هاتفك مسجلا، يرجى إنشاء الحساب الجديد',
            },
            authCode: {
                requiredMsg: 'رمز التحقق غير صحيح، يرجى إعادة تعبئته',
            },
            email: {
                requiredMsg: 'صيغة صندوق البريد غير صحيحة',
                patternMsg: 'لم يكن البريد الإلكتروني مسجلا، يرجى إنشاء حساب جديد',
            },
        },
        codeSuccess: 'تم إرسال رمز التحقق، يرجى فحصه',
        success: 'تم الإرسال بنجاح',
        error: 'فشل الإرسال',
        loginSuccess: 'تم تسجيل الدخول بنجاح',
        account: {
            accountTitle: 'تسجيل الدخول',
            registerTitle: 'تسجيل الدخول',
            resetTitle: 'تعيين كلمة المرور',
            password: 'كلمة المرور',
            placeholder: 'الرجاء إدخال كلمة المرور',
            codeLogin: 'تسجيل الدخول باستخدام رمز التحقق',
            accountLogin: 'تسجيل الدخول باستخدام كلمة المرور',
            forgotPassword: 'هل نسيت كلمة المرور؟',
            registerTips:[
                'هل ليس لديك حساب؟', 
                'تسجيل الدخول', 
                'الحساب موجود بالفعل'
            ],
            checkRule: [
                'من 8 إلى 20 حرفًا',
                'يجب أن يحتوي على حرف كبير واحد على الأقل',
                'يجب أن يحتوي على حرف صغير واحد على الأقل',
                'يجب أن يحتوي على رقم واحد على الأقل', 
                'كلمات المرور غير متطابقة'
            ],
            errorTips: [
                'كلمة المرور خاطئة', 
                'فشل في تسجيل الدخول', 
                'الحساب غير موجود'
            ],
            reset:[
                'إعادة تعيين كلمة المرور', 
                "التالي",
                'لا يمكن أن تكون كلمة المرور الجديدة هي نفسها كلمة المرور السابقة', 
                'تخطي',
                'تم تعيين كلمة المرور بنجاح'
            ],
            setPasswordTips:'يجب أن تتكون كلمة المرور من 8 إلى 20 حرفًا، ويجب أن تحتوي على أحرف كبيرة وأحرف صغيرة وأرقام.',
            confirmPassword: "تأكيد كلمة المرور",
        }
    },
    // 注册页面
    register: {
        mobileRegister: 'تسجيل الهاتف المحمول',
        emailRegister: 'تسجيل البريد الإلكتروني',
        enroll: 'رقم الهاتف المحمول مسجل',
        enrollEmail: 'البريد الإلكتروني مسجل',
        search: 'بحث',
        title: 'حسابات الهاتف والبريد الإلكتروني مستقلة عن الآخر، يرجى ملاحظة نوع حسابك.',
        needRead: {
            agree: 'لقد قرأت ووافقت',
            privacy: '"اتفاقية خصوصية المستخدم"',
        },
        register: 'تسجيل',
        check: 'ضع علامة على "اتفاقية خصوصية المستخدم"',
        newUser: 'تسجيل المستخدم الجديد',
        form: {
            labelList: ['اسم', '*الجنس', '*طول القامة', '*العمر'],
        },
        placeholder: ['يرجى إدخال اسم', 'يرجى إدخال قامتك', 'يرجى إدخال عمرك'],
        unit: ['وحدات مترية', 'وحدات القياس الإمبراطورية ', 'سنة'],
        sexOptions: {
            male: 'ذكر',
            female: 'أنثى',
        },
        btn: 'تقديم',
        rules: {
            metricheight: ['نطاق القامة المسموح بقياسه هو 2 قدم 3 بوصة - 6 أقدام و7 بوصات، يرجى إعادة الإدخال'],
            heightMsg: ['نطاق القامة المسموح بقياسه هو 70-200 سم، يرجى إعادة الإدخال'],
            ageMsg: ['الفئة العمرية المسموح بها 10-99 سنة، يرجى إعادة الإدخال'],
            agePointMsg: ['تم ملء الأعداد غير المدوّرة: يمكن أن يكون العمر عددا مدوّرا فقط، يرجى ملء العمر الصحيح'],
        },
        success: 'تم التسجيل بنجاح',
        userMessage: 'يرجى إدخال اسم المستخدم',
        verify: 'يرجى إدخال رمز التحقق المكون من 4 أرقام',
        errorMsg: 'لا تدعم صيغة القامة المسموح بإدخالها الكسور العشرية، لذا يرجى إعادة إدخالها',
        abnormalMsg: 'التسجيل غير طبيعي',
    },
    // 模型合成
    model: {
        loading: 'جاري إنشاء النموذج، لذا يرجى الانتظار',
        modelLoading: 'جاري تحميل النموذج',
        tabPane: [
            {
                title: 'تقنية الحس الحقيقي العميق',
                msg: 'من خلال المسح الضوئي 3D، يتم التقاط التفاصيل المحلية للجسم بدقة لضمان دقة قياس مقاس المليمتر، بحيث يتغير شكل الجسم بعد تقديم التمرين بوضوح',
            },
            {
                title: 'خوارزمية تكوين الجسم BDA',
                msg: 'يستخدم Visbody نظام قياس تكوين الجسم الأكثر تقدما - خوارزمية BDA لتحديد تكوين الجسم، طريقة الحساب هذه القائمة على حجم الجسم ستقيم بدقة أكبر المخاطر الصحية الناجمة عن السمنة.',
            },
            {
                title: 'العوامل التي تسببت في الأخطاء',
                msg: 'الملابس الفضفاضة أو بعض الزخارف على / في الجسم سوف تسبب أخطاء، يرجى ارتداء الملابس المشدة أو تقليل كمية الملابس على جسمك إذا كنت ترغب في الحصول على البيانات الأكثر دقة.',
            },
            {
                title: 'مدخل التقرير',
                msg: 'يوصى بوضع إشارة مرجعية على صفحة الموقع حتى تتمكن من عرض التقرير لاحقا، يمكنك أيضًا إرسال هذا التقرير إلى صندوق البريد الخاص بك لاحقا.',
            },
        ],
        collect: ['يرجى وضع إشارة مرجعية على صفحة الموقع،', 'حتى تتمكن من عرض التقرير لاحقا'],
        know: 'أنا معلوم',
        reminder: [
            'النصائح',
            'تم إنشاء نموذج 3D الخاص بك، حاول التمرير إلى اليسار واليمين لتدوير النموذج.',
            'تم إنشاء تقريرك ويمكن عرضه بنقر الزر "عرض التقرير".',
            'لم ينجح تقييم وضعية جسمك، يرجى مراجعة تقرير تكوين الجسم.',
            'لم ينجح تكوين جسمك، يرجى مراجعة تقرير تقييم وضعية الجسم.',
            'القياس الخاص بك غير ناجح، يرجى الانتقال إلى الجهاز لإعادة تجربته~',
            'تكوين الجسم غير ناجح، يرجى الانتقال إلى الجهاز لإعادة تجربته~',
            'لم ينجح تقييم وضعية الجسم، يرجى الانتقال إلى الجهاز لإعادة تجربته~',
        ],
        btn: {
            viewReport: 'عرض التقرير',
            bodyReport: 'عرض تقرير تكوين الجسم',
            postureReport: 'عرض تقرير وضعية الجسم',
            lastReport: 'عرض التقرير الأخير',
            loading: 'جاري التحميل...',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'تنزيل التقرير',
            send: 'إرسال تقرير',
            report: 'تقرير',
        },
        reportSuccess: 'تم إنشاء التقرير بنجاح',
        tabPane: {
            labelList: ['وقت القياس', 'عناصر القياس', 'تكوين الجسم', 'تقييم وضعية الجسم', 'وظيفة الكتف'],
        },
        sendEmail: 'إرسال إلى البريد الإلكتروني:',
        requiredMsg: 'يرجى إدخال البريد الإلكتروني الخاص بك',
        patternMsg: 'يرجى إدخال البريد إلكتروني الصحيح',
        send: 'إرسال',
        download: 'تنزيل التقرير',
        sendReport: 'إرسال تقرير',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'تم تعديل الجنس بنجاح',
            height: 'تم تعديل القامة بنجاح',
            age: 'تم تعديل العمر بنجاح',
        },
        logOut: 'تسجيل الخروج',
        personal: 'المركز الشخصي',
        cut: 'تغيير الوحدات / اللغات',
        changeSort: 'تبديل تقرير المنتج',
        setPassword: 'تعيين/تعديل كلمة المرور',
        describe: 'بعد تبديل طراز المنتج، سيتم عرض جميع تقارير القياس بموجب هذا الطراز.',
        describeBtn: 'بعد تبديل طراز المنتج، ستتم إعادة توجيهك إلى أحدث تقرير قياس ضمن هذا الطراز.',
        descReport: 'تم تبديل تقرير المنتج بنجاح',
        unit: ['النظام المتري (كجم، سم)', 'النظام الإمبراطوري (قدم، انش، رطل)'],
        read: {
            reading: 'قراءة',
            privacy: 'اتفاقية خصوصية المستخدم',
        },
        setting: 'إعدادات',
        form: {
            labelList: ['رقم الهاتف', 'وحدات', 'اللغات', 'لقب', 'الجنس', 'القامة', 'العمر'],
        },
        placeholder: ['تعديل الاسم', 'تعديل الجنس', 'تعديل القامة', 'تعديل العمر'],
        btn: {
            cancelBtn: 'إلغاء',
            accomplishBtn: 'إكمال',
            confirmBtn: 'تأكيد',
        },
        dialog: [
            'الجنس هو أحد القواعد المهمة لقياس تكوين الجسم، ستكون البيانات المقاسة بعد التعديل مختلفة عن البيانات السابقة. يرجى تأكيد ما إذا كنت تريد تغييره؟',
            'القامة هي إحدى القواعد المهمة لقياس تكوين الجسم، ستكون البيانات المقاسة بعد التعديل مختلفة عن البيانات السابقة. يرجى تأكيد ما إذا كنت تريد تغييره؟',
            'العمر هو أحد القواعد المهمة لقياس تكوين الجسم، ستكون البيانات المقاسة بعد التعديل مختلفة عن البيانات السابقة. يرجى تأكيد ما إذا كنت تريد تغييره؟',
        ],
        rules: {
            metricheight: ['يرجى ملء القامة ضمن النطاق (2 قدم 3 انش - 6 أقدام و 7 انش)'],
            heightMsg: ['يرجى ملء القامة ضمن النطاق (70-200 سم)'],
            ageMsg: [
                'يرجى ملء الفئة العمرية (10-99 سنة)',
                'يتم ملء الأعداد غير المدوّرة: يمكن أن يكون العمر عددا مدوّرا فقط، يرجى ملء العمر الصحيح',
            ],
        },
        heightMsg: 'يرجى إعادة تحديد القامة',
        ageMsg: 'يرجى إعادة تحديد العمر',
        ModelBinding: 'يبدو أن شخصا ما قد قام بمسح الرمز ضوئيا بالفعل، يرجى إعادة القياس!',
        ReportEmpty: {
            title: 'ليس لديك تقرير الاختبار حتى الآن',
            desc: 'تعال إلى الماسح الضوئي للجسم Visbody 3D وقم باتخاذ القياس!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'يبدو أن شخصا ما قد قام بمسح الرمز ضوئيا بالفعل، يرجى إعادة القياس!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'ليس لديك تقرير الاختبار حتى الآن',
        desc: 'تعال إلى الماسح الضوئي للجسم Visbody 3D وقم باتخاذ القياس!',
        descS30: '找到 Visbody-S30 3D 体测精灵，进行一次测量吧！',
        descM30: '找到 Visbody-M30 3D 体测精灵，进行一次测量吧！',
    },
    // 用户信息
    userInfo: {
        height: 'القامة:',
        age: 'العمر:',
        unit: 'سنة',
        weight: 'الوزن',
    },
    // 体成分
    mass: {
        title: 'النظرة العامة على تقييم تكوين الجسم',
        titles: 'تكوين الجسم',
        springFrame: '',
        massFrame: 'لا توجد بيانات القياس لليوم',
        contrast: 'يرجى اختيار تقرير واحد للمقارنة',
        contrastReport: 'يرجى اختيار تقرير واحد للمقارنة',
        noRecord: 'لا توجد سجلات',
        score: 'نقاط',
        status: 'حالة تكوين جسمك الحالية',
        WT: 'الوزن',
        PBF: 'نسبة مؤوية لدهون الجسم',
        endSymbol: '،',
        // 测量相关
        measure: {
            value: 'قيمة القياس',
            standardRange: 'النطاق المعياري',
            comparedWithLastLime: 'مقارنة بالسابق',
            comparedNet: 'مقارنة بالسابق',
            current: '本次测量分数',
            WT: 'الوزن',
            FFM: 'كتلة الجسم اللادهنية',
            BFM: 'كتلة دهون الجسم',
            LM: 'كتلة العضلات',
            TBW: 'مياه الجسم',
            SM: 'العضلة الهيكلية',
            PROTEIN: 'بروتين',
            TM: 'أملاح غير عضوية',
            BMR: 'معدل الأيض الأساسي',
            WHR: 'نسبة الخصر إلى الورك',
            BMI: 'مؤشر كتلة الجسم',
            PBF: 'نسبة الدهون في الجسم',
            VFG: 'مستوى الدهون الحشوية',
            unit: 'كجم',
            BMRUnit: 'كيلو كالوري/ اليوم',
        },
        // 定义解读
        explain: [
            {
                title: 'الوزن',
                msg: 'الوزن هو مجموع الماء في الجسم، البروتين، الملح غير العضوي ووزن الجسم.',
            },
            {
                title: 'كتلة الجسم اللادهنية',
                msg: 'كتلة الجسم اللادهنية هي إجمالي وزن الجسم بدون الدهون',
            },
            {
                title: 'كتلة دهون الجسم',
                msg: 'الدهون في الجسم هي مجموع الدهون تحت الجلد والدهون الحشوية ودهون العضلات.',
            },
            {
                title: 'كتلة العضل',
                msg: 'الكتلة اللادهنية الناعمة هي كتلة الجسم اللادهنية، والتي تشمل العضل الهيكلي، العضلات الملساء وعضلة القلب.',
            },
            {
                title: 'مياه الجسم',
                msg: 'معظم جسم الإنسان عبارة عن ماء بنسبة 50٪ -70٪ من وزن الجسم. وتتواجد مياه الجسم بشكل رئيسي في الخلايا البشرية وسوائل الجسم، ومعظمها موجود في خلايا العضلات.',
            },
            {
                title: 'العضلة الهيكلية',
                msg: 'العضلات الهيكلية، والمعروفة أيضاً باسم العضلات المخططة، هي نوع من العضلات المرتبطة بالعظام. تحتوي البيانات على كمية العضلة الهيكلية.',
            },
            {
                title: 'بروتين',
                msg: 'البروتين مادة صلبة مع الأمونيا الموجودة في جميع خلايا جسم الإنسان. وهو المكون الرئيسي لكتلة العضلات.',
            },
            {
                title: 'أملاح غير عضوية',
                msg: 'يتكون جسم الإنسان من مادة عضوية ومواد غير عضوية وماء. المادة غير العضوية هنا هي أملاح غير عضوية تشكل 5٪ من وزن الجسم.',
            },
            {
                title: 'معدل الأيض الأساسي',
                msg: 'الأيض الأساسي هو إجمالي الطاقة المستهلكة في اليوم عندما يكون الجسم في حالة راحة، بدون تأثير التمارين الرياضية، والأشياء البدنية، والعصبية، والتغيرات الخارجية في درجة الحرارة، وما إلى ذلك.',
            },
            {
                title: 'نسبة الخصر إلى الورك (WHR)',
                msg: 'نسبة الخصر إلى محيط الورك، هي مؤشر مهم لتحديد السمنة المركزية.',
            },
            {
                title: 'مؤشر كتلة الجسم (BMI)',
                msg: 'يستخدم مؤشر كتلة الجسم (BMI ) بشكل أساسي لتقييم مظهر السمنة ، وهو معيار شائع لقياس السمنة في الجسم.',
            },
            {
                title: 'نسبة الدهون في الجسم (BFP)',
                msg: 'نسبة الدهون في الجسم (BFP) هي قياس تكوين الجسم ويحدد كمية الدهون من وزن الجسم.',
            },
            {
                title: 'مستوى الدهون الحشوية',
                msg: 'مستوى الدهون الحشوية هي مؤشر مهم لتقييم ما إذا كانت السمنة الخفية.',
            },
            {
                title: 'السائل داخل الخلايا',
                msg: 'السائل داخل الخلايا: هو سائل الجسم الموجود في خلايا الجسم ككل وهو مكون أساسي للبروتوبلازم.',
            },
            {
                title: 'السائل خارج الخلية',
                msg: 'السائل خارج الخلية: يشير عادةً إلى سوائل الجسم الموجودة خارج الخلية، بما فيها البلازما والسائل الخلالي بين الأوعية الدموية وخلايا الأنسجة.',
            },
            {
                title: 'العمر الأيضي',
                msg: 'يشير العمر الأيضي إلى أداء الجسم ووظائفه الصحية المتعلقة بالتمثيل الغذائي.',
            },
        ],
        // 节段
        segment: {
            fat: 'الدهون الجزئية',
            muscle: 'العضلات الجزئية',
            right: 'يمين',
            left: 'يسار',
            standard: 'معيار',
            lowStandard: 'معيار منخفض',
            superStandard: 'معيار عال',
            peel: '(الوزن الفارغ)',
        },
    },
    // 体态
    shape: {
        title: 'النظرة العامة على تقييم وضعية الجسم',
        titles: 'تقييم وضعية الجسم',
        status: 'حالتك البدنية الحالية',
        suggest: 'اقتراح',
        possibility: '، تواجد',
        models: {
            front: 'الوجه الأمامي',
            left: 'الجانب الأيسر',
            right: 'الجانب الأيمن',
            top: 'الوجه الخلفي',
            low: 'منخفض قليلا',
            high: 'مرتفع قليلا',
            normal: 'عادي',
        },
        item: {
            deviate: 'قيمة القياس',
            result: 'تعليمات',
            normal: 'عادي',
			keAbnormal: 'احتمال وجود حالة غير طبيعية',
            abnormal: 'غير عادي',
            head: 'وضعية الرأس للأمام',
            headSlant: 'انحناء الرأس',
            roundShoulderLeft: 'كتفين مستديرين لليسار',
            roundShoulderRight: 'كتفين مستديرين لليمين',
            highLowShoudler: 'كتفين غير مستويين',
            pelvis: 'انحناء الحوض الأمامي/الخلفي',
            leftKneeCheck: 'تقييم الركبة اليسرى',
            rightKneeCheck: 'تقييم الركبة اليمنى',
            leg: 'نوع الساق',
            leftLeg: 'الساق اليسرى:',
            rightLeg: 'الساق اليمنى:',
        },
        // 定义解读
        explain: [
            {
                title: 'وضعية الرأس للأمام',
                msg: 'الزاوية بين نقطة الأذن اليسرى والخط الذي يربط مركز الرقبة والخط المتوسط على الجانب.',
            },
            {
                title: 'انحناء الرأس',
                msg: 'الزاوية بين نقطة منتصف الرأس الأمامي ومركز الرقبة والخط المتوسط للجبهة.',
            },
            {
                title: 'كتفين مستديرين لليسار',
                msg: 'الزاوية بين الخط الذي يربط أعلى نقطة على الجانب الأيسر من الظهر وخط الظل للكتف.',
            },
            {
                title: 'كتفين مستديرين لليمين',
                msg: 'الزاوية بين الخط الذي يربط أعلى نقطة على الجانب الأيمن من الظهر وخط الظل للكتف.',
            },
            {
                title: 'كتفين غير مستويين',
                msg: 'المسافة بين النقاط الأخرمية اليسرى واليمنى على الظهر في الاتجاه الرأسي.',
            },
            {
                title: 'انحناء الحوض الأمامي/الخلفي',
                msg: 'زاوية الخط المكون من ثلاث نقاط الذي يربط النقطة المركزية للرقبة ونقطة الورك والكاحل على الجانب الأيسر.',
            },
            {
                title: 'تقييم الركبة اليسرى',
                msg: 'زاوية الخط المكون من ثلاث نقاط لنقطة الورك اليسرى للوجه ومفصل الركبة ونقطة الكاحل.',
            },
            {
                title: 'تقييم الركبة اليمنى',
                msg: 'زاوية الخط المكون من ثلاث نقاط لنقطة الورك اليمنى للوجه ومفصل الركبة ونقطة الكاحل.',
            },
            {
                title: 'نوع الساق',
                msg: 'الزاوية بين نقطة عظم الورك ومفصل الركبة ونقطة الكاحل في مقدمة الساقين اليسرى واليمنى.',
            },
        ],
        exception: [
            {
                title: 'يميل إلى الجانب الأيسر',
                abnormal: '',
            },
            {
                title: 'يميل إلى الجانب الأيمن',
                abnormal: '',
            },
            {
                title: 'عالية من اليسار ',
                abnormal: '',
            },
            {
                title: 'عالية من اليمين ',
                abnormal: '',
            },
            {
                title: 'إمالة الحوض الخلفية',
                abnormal: '',
            },
            {
                title: 'إمالة الحوض الأمامية',
                abnormal: '',
            },
            {
                title: 'فرط تمدد الركبة اليسرى',
                abnormal: '',
            },
            {
                title: 'الانحناء الأمامي للركبة اليسرى',
                abnormal: '',
            },
            {
                title: 'فرط تمدد الركبة اليمنى',
                abnormal: '',
            },
            {
                title: 'الانحناء الأمامي للركبة اليمنى',
                abnormal: '',
            },
            {
                title: 'أرجل على شكل حرف K',
                abnormal: '',
            },
            {
                title: 'أرجل على شكل حرف D',
                abnormal: '',
            },
            {
                title: 'أرجل على شكل حرف X',
                abnormal: '',
            },
            {
                title: 'أرجل على شكل حرف O',
                abnormal: '',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'المسافة العمودية بين الأذنين وخط الجاذبية للجسم',
            },
            {
                msg: 'الزاوية بين الخط الواصل بين الأذنين والمستوى الأفقي',
            },
            {
                msg: 'المسافة العمودية بين النتوء الأخرمي الأيسر وخط الجاذبية',
            },
            {
                msg: 'المسافة العمودية بين النتوء الأخرمي الأيمن وخط الجاذبية',
            },
            {
                msg: 'الفرق بين خطي الربط بين قاعدة الرقبة والنتوءات الأخرمية على الجانبين',
            },
            {
                msg: 'المسافة العمودية بين مفصل الورك وخط الجاذبية للجسم',
            },
            {
                msg: 'الزاوية بين مفصل الكاحل ونقطة 1 سم أسفل مفصل الركبة اليسرى ومفصل الورك في المستوى السهمي',
            },
            {
                msg: 'الزاوية بين مفصل الكاحل ونقطة 1 سم أسفل مفصل الركبة اليمنى ومفصل الورك في المستوى السهمي',
            },
            {
                msg: 'الزاوية بين مفاصل الورك والركبة والكاحل الثنائية في المستوى الإكليلي',
            },
        ],
        item: {
            pelvis: 'الميل الأمامي للحوض',
        },
    },
    // 围度信息
    girth: {
        title: 'محيط الجسم',
        present: 'هذه المرة',
        noHistory: 'لا توجد بيانات',
        tpl: [
            {
                title: 'محيط الرقبة',
                key: 'قياس محيط الرقبة',
            },
            {
                title: 'محيط الذراع العلوي الأيسر',
                key: 'قياس محيط الذراع العلوي الأيسر',
            },
            {
                title: 'محيط الذراع العلوي الأيمن',
                key: 'قياس محيط الذراع العلوي الأيمن',
            },
            {
                title: 'محيط الصدر',
                key: 'قياس محيط الصدر',
            },
            {
                title: 'محيط خصر مرتفع',
                key: 'قياس محيط خصر مرتفع',
            },
            {
                title: 'محيط منتصف الخصر',
                key: 'قياس محيط منتصف الخصر',
            },
            {
                title: 'محيط الورك',
                key: 'قياس محيط الورك',
            },
            {
                title: 'محيط الفخذ الأيسر',
                key: 'قياس محيط الفخذ الأيسر',
            },
            {
                title: 'محيط وسط الفخذ الأيسر',
                key: 'قياس محيط وسط الفخذ الأيسر',
            },
            {
                title: 'الحد الأدنى لمحيط الفخذ الأيسر',
                key: 'الحد الأدنى لقياس محيط الفخذ الأيسر',
            },
            {
                title: 'محيط الفخذ الأيمن',
                key: 'قياس محيط الفخذ الأيمن',
            },
            {
                title: 'محيط وسط الفخذ الأيمن',
                key: 'قياس محيط وسط الفخذ الأيمن',
            },
            {
                title: 'الحد الأدنى لمحيط الفخذ الأيمن',
                key: 'الحد الأدنى لقياس محيط الفخذ الأيمن',
            },
            {
                title: 'محيط الساق اليسرى',
                key: 'قياس الساق اليسرى',
            },
            {
                title: 'محيط الساق اليمنى',
                key: 'قياس الساق اليمنى',
            },
            {
                title: 'محيط خصر منخفض',
                key: 'قياس محيط الخصر المنخفض',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'وظيفة الكتف',
        model: {
            val: 'قيمة القياس',
            scope: 'الحد الأقصى لموقع النشاط',
            diff: 'مقارنة بالسابق',
        },
        normal: 'لم يتم الكشف عن تشوهات ملحوظة.',
        normalAll: 'لم يتم الكشف عن أي تشوهات كبيرة في وظائف الكتف.',
        conclusion: 'استنتاج',
        analyse: 'تحليل:',
        suggest: 'اقتراح:',
        cause: 'يرجى طلب المشورة من الخبراء للحصول على التفاصيل.',
    },
    reportTips: {
        title: 'يرجى الاتصال بالمسؤول إذا كنت تريد حذف التقرير.',
        btnMsg: 'حسنًا',
        alaryTips: 'تم حذف هذا التقرير من قبل المسؤول، سوف نقفز إلى التقرير التالي!',
    },
    saveQr: {
        title: 'تقرير الماسح الضوئي للجسم Visbody 3D',
        btnMsg: 'اضغط لفترة طويلة على الصورة لحفظها على هاتفك',
        tips: 'إذا وجدت أنه لا يمكنك الحفظ، يرجى الانتقال إلى [الإعدادات] وفتح الأذونات المقابلة',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
        monthTitle: function monthTitle(year, month) {
            return month + '/' + year
        },
        rangePrompt: function rangePrompt(maxRange) {
            return 'Choose no more than ' + maxRange + ' days'
        },
    },
    share: {
        title: 'يرجى نسخ الرابط أدناه ومشاركته',
        btnMsg: 'نسخ',
    },
    ...arLocale,
    ...agrEn,
}
