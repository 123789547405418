import zhLocale from 'element-ui/lib/locale/lang/en'
import agrZh from './agr-en'
export default {
    // ai报告相关
    ai: {
        report: "วิเคราะห์รายงาน AI",
        train: "แผนการอบรม",
        loading: "กำลังโหลด...",
        loadingError1: "โหลดไม่สำเร็จ",
        loadingError2: "ขออภัย โปรดลองใหม่อีกครั้ง",
    },
    // 登录页面
    login: {
        loginAndRegister: 'สมัครสมาชิก / เข้าสู่ระบบ',
        registerAndLogin: 'เข้าสู่ระบบ / สมัครสมาชิก',
        userLogin: 'เข้าสู่ระบบ',
        title: 'บัญชีมือถือและอีเมลไม่เชื่อมโยงกัน; กรุณาทราบประเภทบัญชีของคุณ',
        mobileLogin: 'โทรศัพท์',
        emailLogin: 'อีเมล',
        email: 'อีเมล',
        loginBtn: 'เข้าสู่ระบบ',
        autoLogin: 'จดจำฉัน',
        retransmission: 's',
        formList: [
            {
                label: 'ใส่หมายเลขโทรศัพท์ของคุณ',
                placeholder: 'ส่ง',
            },
            {
                label: 'รหัสยืนยัน',
                placeholder: 'ใส่รหัสยืนยัน',
            },
            {
                label: 'กรุณาใส่อีเมลของคุณ',
            },
        ],
        btn: {
            submit: 'สร้างบัญชี',
            title: 'เพื่อให้ข้อมูลถูกต้อง, การใส่ส่วนสูง, เพศและอายุที่ถูกต้องเป็นสิ่งที่สำคัญ',
        },
        rule: {
            phone: {
                requiredMsg: 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง',
                patternMsg: 'ไม่ได้ลงทะเบียนโทรศัพท์, สร้างบัญชีของคุณ',
            },
            authCode: {
                requiredMsg: 'รหัสยืนยันผิด, กรุณาพิมพ์ใหม่',
            },
            email: {
                requiredMsg: 'รูปแบบอีเมลไม่ถูกต้อง',
                patternMsg: 'ไม่ได้ลงทะเบียนอีเมล, สร้างบัญชีของคุณ',
            },
        },
        codeSuccess: 'รหัสยืนยันได้ถูกส่งแล้ว กรุณาตรวจสอบ',
        success: 'การส่งสำเร็จ',
        error: 'การส่งล้มเหลว',
        loginSuccess: 'เข้าสู่ระบบสำเร็จ',
        account: {
            accountTitle: 'เข้าสู่ระบบ',
            registerTitle: 'ลงชื่อเข้าใช้',
            resetTitle: 'ตั้งรหัสผ่าน',
            password: 'รหัสผ่าน',
            placeholder: 'กรุณากรอกรหัสผ่าน',
            codeLogin: 'เข้าสู่ระบบด้วยรหัสยืนยัน',
            accountLogin: 'เข้าสู่ระบบด้วยรหัสผ่าน',
            forgotPassword: 'ลืมรหัสผ่านหรือไม่?',
            registerTips:[
                'ยังไม่มีบัญชีหรือ?', 
                'ลงชื่อเข้าใช้', 
                'บัญชีมีอยู่แล้ว'
            ],
            checkRule: [
                '8-20 ตัวอักษร',
                'ต้องมีอักษรตัวใหญ่ไม่น้อยกว่าหนึ่งตัว',
                'ต้องมีอักษรตัวเล็กไม่น้อยกว่าหนึ่งตัว',
                'ต้องมีตัวเลขไม่น้อยกว่าหนึ่งตัว', 
                'รหัสผ่านไม่ตรงกัน'
            ],
            errorTips: [
                'รหัสผ่านผิด', 
                'การเข้าสู่ระบบล้มเหลว', 
                'บัญชีไม่พบ'
            ],
            reset:[
                'รีเซ็ตรหัสผ่าน', 
                "ถัดไป",
                'รหัสผ่านใหม่ไม่สามารถเหมือนกับรหัสผ่านก่อนหน้าได้', 
                'ข้าม',
                'ตั้งรหัสผ่านสำเร็จ'
            ],
            setPasswordTips:'รหัสผ่านต้องมีความยาว 8-20 อักขระ และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก และตัวเลข',
            confirmPassword: "ยืนยันรหัสผ่าน",
        }
    },
    // 注册页面
    register: {
        mobileRegister: 'โทรศัพท์',
        emailRegister: 'อีเมล',
        enroll: 'หมายเลขโทรศัพท์มือถือที่ลงทะเบียนแล้ว',
        enrollEmail: 'อีเมลถูกลงทะเบียนแล้ว',
        search: 'ค้นหา',
        title: 'บัญชีที่ตั้งขึ้นโดยอีเมลและหมายเลขโทรศัพท์เป็นอิสระต่อกัน, กรุณา',
        needRead: {
            agree: 'ฉันยอมรับ',
            privacy: '(นโยบายความเป็นส่วนตัว)',
        },
        register: 'ลงชื่อเข้าใช้',
        check: 'ตรวจสอบ " ความเป็นส่วนตัวของผู้ใช้ "',
        newUser: 'ลงชื่อเข้าใช้',
        form: {
            labelList: ['ชื่อ', '* เพศ', '* ส่วนสูง', '* อายุ'],
        },
        placeholder: ['กรุณาใส่ชื่อของคุณ', 'กรุณาใส่ส่วนสูงของคุณ', 'กรุณาใส่อายุของคุณ'],
        unit: ['เมตริก', 'ระบบอิมพีเรียล', ''],
        sexOptions: {
            male: 'ชาย',
            female: 'หญิง',
        },
        btn: 'ส่ง',
        rules: {
            metricheight: [`ช่วงส่วนสูงคือ 2'3'' - 6'7'', กรุณาใส่ใหม่`],
            heightMsg: ['ช่วงส่วนสูงคือ 70 - 200 cm, กรุณาใส่ใหม่'],
            ageMsg: ['ช่วงอายุคือ 10 - 99, กรุณาใส่ใหม่'],
            agePointMsg: ['ได้รับข้อมูลที่ไม่ใช่จำนวนเต็ม: อายุต้องเป็นจำนวนเต็มเท่านั้น, กรุณาใส่อายุที่ถูกต้อง'],
        },
        success: 'การลงทะเบียนสำเร็จ',
        userMessage: 'ใส่ชื่อผู้ใช้',
        verify: 'ใส่รหัสยืนยัน 4 หลัก',
        errorMsg: 'รูปแบบส่วนสูงไม่รองรับทศนิยม, กรุณาใส่ใหม่',
        abnormalMsg: 'การลงทะเบียนผิดปกติ',
    },
    // 模型合成
    model: {
        loading: 'กำลังโหลด...กรุณารอสักครู่',
        modelLoading: 'การโหลดแบบจำลอง 3D',
        tabPane: [
            {
                title: 'เทคโนโลยี Deep Real Sense',
                msg: 'สะสมรายละเอียดท้องถิ่นของร่างกายผ่านการสแกน 3D ให้ความแม่นยำได้ถึงระดับมิลลิเมตร และแสดงผลเปลี่ยนแปลงรูปร่างของร่างกายหลังการออกกำลังกายอย่างชัดเจน',
            },
            {
                title: 'อัลกอริทึมการวัดคุณสมบัติร่างกาย BDA',
                msg: 'Visbody ใช้ระบบการวัดคุณสมบัติร่างกายที่ทันสมัยที่สุด - อัลกอริทึม BDA เพื่อกำหนดคุณสมบัติร่างกาย วิธีการคำนวณนี้ที่อิงตามปริมาตรร่างกายจะประเมินความเสี่ยงทางสุขภาพที่เกิดจากโรคอ้วนอย่างถูกต้องมากขึ้น',
            },
            {
                title: 'ปัจจัยที่ทำให้เกิดข้อผิดพลาด',
                msg: 'การสวมใส่เสื้อผ้าหลวม หรือตกแต่งบางอย่างบน/ในร่างกายจะทำให้เกิดข้อผิดพลาด กรุณาสวมใส่เสื้อเบาหรือลดจำนวนเสื้อผ้าบนร่างกายของคุณถ้าคุณต้องการข้อมูลที่แม่นยำที่สุด',
            },
            {
                title: 'ขอรายงานของคุณ',
                msg: 'แนะนำให้ทำการบุ๊กมาร์ก URL ของหน้าเว็บเพื่อชมรายงานได้ง่ายในอนาคต คุณยังสามารถส่งรายงานนี้ไปที่กล่องจดหมายของคุณเพื่อบันทึกไว้ในภายหลัง',
            },
        ],
        collect: ['กรุณาทำการบุ๊กมาร์กหน้านี้ เพื่อที่จะทบทวนรายงานได้ง่ายในอนาคต', ''],
        know: 'ฉันเข้าใจแล้ว',
        reminder: [
            'กรุณาเตือน',
            'มีการสร้างรูปแบบ 3D ของคุณแล้ว คุณสามารถหมุนรูปแบบด้วยการเลื่อนไปทางซ้ายหรือขวา',
            'รายงานของคุณได้ถูกสร้างแล้วและสามารถดูได้โดยการคลิกที่ปุ่ม "ดูรายงาน"',
            'การประเมินร่างกายของคุณไม่สำเร็จ กรุณาดูรายงานส่วนประกอบร่างกาย',
            'การวัดคุณสมบัติร่างกายของคุณไม่สำเร็จ, กรุณาทบทวนรายงานการวัดท่าทาง',
            'การวัดของคุณไม่สำเร็จ, กรุณาไปที่อุปกรณ์และทดสอบอีกครั้ง',
            'การวัดคุณสมบัติร่างกายไม่สำเร็จ, กรุณาไปที่อุปกรณ์และทดสอบอีกครั้ง',
            'การประเมินท่าทางไม่สำเร็จ, กรุณาไปที่อุปกรณ์และทดสอบอีกครั้ง',
        ],
        btn: {
            viewReport: 'ดูรายงาน',
            bodyReport: 'ดูรายงานส่วนประกอบของร่างกาย',
            postureReport: 'ดูรายงานท่าทางร่างกาย',
            lastReport: 'ดูรายงานล่าสุด',
            loading: 'กำลังโหลด',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'ดาวน์โหลดรายงาน',
            send: 'ส่งรายงาน',
            report: 'รายงาน',
        },
        reportSuccess: 'สำเร็จ!',
        tabPane: {
            labelList: ['เวลาวัด', 'รายการ', 'ส่วนประกอบของร่างกาย', 'ประเมินท่าทาง', 'ฟังก์ชันของไหล่'],
        },
        sendEmail: 'อีเมล:',
        requiredMsg: 'ป้อนที่อยู่อีเมลของคุณ',
        patternMsg: 'กรุณาใส่ที่อยู่อีเมลที่ถูกต้อง',
        send: 'ส่ง',
        download: 'ดาวน์โหลดรายงาน',
        sendReport: 'ส่งรายงาน',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'เปลี่ยนเพศสำเร็จ',
            height: 'เปลี่ยนความสูงสำเร็จ',
            age: 'เปลี่ยนอายุสำเร็จ',
        },
        logOut: 'ออกจากระบบ',
        personal: 'ศูนย์ส่วนบุคคล',
        cut: 'เปลี่ยนหน่วย / ภาษา',
        changeSort: 'สวิตช์รายงานผลิตภัณฑ์',
        setPassword: 'ตั้งค่า/แก้ไขรหัสผ่าน',
        describe: '* หลังจากสลับโมเดลผลิตภัณฑ์ รายงานการวัดทั้งหมดใต้โมเดลนี้จะถูกแสดง',
        describeBtn: 'หลังจากสลับโมเดลผลิตภัณฑ์ คุณจะถูกนำไปยังรายงานการวัดล่าสุดใต้โมเดลนี้',
        descReport: 'สำเร็จในการสลับรายงานผลิตภัณฑ์',
        unit: ['เมตริก (กก., ซม.)', 'อิมพีเรียล (ฟุต, นิ้ว, ปอนด์)'],
        read: {
            reading: 'อ่าน',
            privacy: '(นโยบายความเป็นส่วนตัว)',
        },
        setting: 'การตั้งค่า',
        form: {
            labelList: ['โทรศัพท์', 'หน่วย', 'ภาษา', 'ชื่อ', 'เพศ', 'ความสูง', 'อายุ'],
        },
        placeholder: ['เปลี่ยนชื่อผู้ใช้ของคุณ', 'เพศใหม่', 'อัพเดทความสูง', 'อัพเดทอายุ'],
        btn: {
            cancelBtn: 'ยกเลิก',
            accomplishBtn: 'เสร็จสิ้น',
            confirmBtn: 'ยืนยัน',
        },
        dialog: [
            'เพศเป็นหนึ่งในพื้นฐานสำคัญในการวัดองค์ประกอบร่างกาย ข้อมูลที่วัดได้หลังจากการแก้ไขจะต่างจากข้อมูลก่อนหน้า กรุณายืนยันหากคุณต้องการเปลี่ยน',
            'ส่วนสูงเป็นหนึ่งในพื้นฐานสำคัญในการวัดองค์ประกอบร่างกาย ข้อมูลที่วัดได้หลังจากการแก้ไขจะต่างจากข้อมูลก่อนหน้า กรุณายืนยันหากคุณต้องการเปลี่ยน',
            'อายุเป็นหนึ่งในพื้นฐานสำคัญในการวัดองค์ประกอบร่างกาย ข้อมูลที่วัดได้หลังจากการแก้ไขจะต่างจากข้อมูลก่อนหน้า กรุณายืนยันหากคุณต้องการเปลี่ยน',
        ],
        rules: {
            metricheight: [`กรุณากรอกช่วงส่วนสูงที่ถูกต้อง (2'3" ถึง 6'7")`],
            heightMsg: ['กรุณากรอกส่วนสูงในช่วง 70 ซม. - 200 ซม.'],
            ageMsg: [
                'กรุณากรอกอายุในช่วง 10 - 99 ปี',
                'กรอกไม่เป็นจำนวนเต็ม, อายุจะต้องเป็นจำนวนเต็มเท่านั้น กรุณากรอกอายุที่ถูกต้อง',
            ],
        },
        heightMsg: 'กรุณาเลือกส่วนสูงใหม่',
        ageMsg: 'กรุณาเลือกอายุใหม่',
        ModelBinding: 'ดูเหมือนว่ามีคนสแกนรหัสนี้แล้ว กรุณาวัดใหม่!',
        ReportEmpty: {
            title: 'คุณยังไม่มีรายงาน',
            desc: 'มาที่เครื่องสแกนร่างกาย 3D ของ Visbody แล้วทดสอบใหม่!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'ดูเหมือนว่ามีคนสแกนรหัสนี้แล้ว กรุณาวัดใหม่!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'คุณยังไม่มีรายงาน',
        desc: 'มาที่เครื่องสแกนร่างกาย 3D ของ Visbody แล้วทดสอบใหม่!',
        descS30: 'มาที่เครื่องสแกนร่างกาย 3D ของ Visbody-S30 แล้วทดสอบใหม่!',
        descM30: 'มาที่เครื่องสแกนร่างกาย 3D ของ Visbody-M30 แล้วทดสอบใหม่!',
    },
    // 用户信息
    userInfo: {
        height: 'ส่วนสูง:',
        age: 'อายุ:',
        unit: '',
        weight: 'น้ำหนัก',
    },
    // 体成分
    mass: {
        title: 'ภาพรวมการประเมินส่วนประกอบของร่างกาย',
        titles: 'ส่วนประกอบของร่างกาย',
        springFrame: '',
        massFrame: 'ไม่มีข้อมูลการวัดสำหรับวันนี้',
        contrast: 'เลือกรายงานเพื่อเปรียบเทียบ',
        contrastReport: 'เลือกรายงานเพื่อเปรียบเทียบ',
        noRecord: 'ไม่มีรายการจดบันทึก',
        score: 'คะแนน',
        status: 'สถานะส่วนประกอบของร่างกายในปัจจุบันของคุณ',
        WT: 'น้ำหนัก',
        PBF: 'เปอร์เซ็นไขมันในร่างกาย',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'ค่า',
            standardRange: 'ช่วงมาตรฐาน',
            comparedWithLastLime: 'สุทธิ',
            comparedNet: 'เทียบกับคะแนนครั้งสุดท้าย',
            current: '本次测量分数',
            WT: 'น้ำหนัก',
            FFM: 'น้ำหนักตัวที่ปราศจากไขมัน',
            BFM: 'มวลไขมัน',
            LM: 'มวลกล้ามเนื้อ',
            TBW: 'ปริมาณน้ำในร่างกาย',
            SM: 'มวลกล้ามเนื้อติดกระดูก',
            PROTEIN: 'โปรตีน',
            TM: 'แร่ธาตุ',
            BMR: 'อัตราการเผาผลาญพลังงานขั้นพื้นฐาน',
            WHR: 'อัตราส่วนของรอบเอวต่อรอบสะโพก',
            BMI: 'ค่าดัชนีมวลกาย',
            PBF: 'เปอร์เซ็นไขมันในร่างกาย',
            VFG: 'ระดับไขมันในช่องท้อง',
            unit: 'กก.',
            BMRUnit: 'กิโลแคลอรี่/วัน',
        },
        // 定义解读
        explain: [
            {
                title: 'น้ำหนัก',
                msg: 'น้ำหนักเป็นผลรวมของน้ำในร่างกาย, โปรตีน, เกลือแร่และน้ำหนัก.',
            },
            {
                title: 'น้ำหนักตัวที่ปราศจากไขมัน',
                msg: 'น้ำหนักแห่งร่างกายที่ไม่มีไขมันเป็นน้ำหนักรวมของร่างกายโดยไม่รวมไขมัน.',
            },
            {
                title: 'มวลไขมัน',
                msg: 'น้ำหนักของไขมันในร่างกายเป็นผลรวมของไขมันใต้ผิวหนัง, ไขมันอวัยวะภายใน และไขมันระหว่างกล้ามเนื้อ.',
            },
            {
                title: 'มวลกล้ามเนื้อ',
                msg: 'น้ำหนักกล้ามเนื้ออ่อนเป็นน้ำหนักของร่างกายที่ไม่มีไขมัน ซึ่งรวมถึงกล้ามเนื้อโครงกระดูก, กล้ามเนื้อเรียบ, และกล้ามเนื้อหัวใจ.',
            },
            {
                title: 'ปริมาณน้ำในร่างกาย',
                msg: 'ส่วนใหญ่ของร่างกายคนเป็นน้ำ ที่มีจำนวนระหว่าง 50%-70% ของน้ำหนักร่างกาย. และน้ำในร่างกายส่วนใหญ่อยู่ในเซลล์มนุษย์และของเหลวในร่างกาย, ส่วนใหญ่อยู่ในเซลล์กล้ามเนื้อ.',
            },
            {
                title: 'มวลกล้ามเนื้อติดกระดูก',
                msg: 'น้ำหนักกล้ามเนื้อโครงกระดูกเป็นประเภทของกล้ามเนื้อสายที่แนบเนื่องกับกระดูก. ผลการคำนวณนี้รวมถึงปริมาณของกล้ามเนื้อโครงกระดูก.',
            },
            {
                title: 'โปรตีน',
                msg: 'โปรตีนเป็นสารข้นที่มีแอมโมเนีย, ซึ่งมีอยู่ในทุกเซลล์ของร่างกายมนุษย์. มันเป็นส่วนประกอบหลักของเนื้อเยื่อกล้ามเนื้อ.',
            },
            {
                title: 'แร่ธาตุ',
                msg: 'ร่างกายมนุษย์ประกอบด้วยสารอินทรีย์, สารไร้อินทรีย์ และน้ำ สารไร้อินทรีย์ในที่นี้หมายถึงเกลือแร่ธรรมชาติ ซึ่งคิดเป็น 5% ของน้ำหนักร่างกาย',
            },
            {
                title: 'อัตราการเผาผลาญพลังงานขั้นพื้นฐาน',
                msg: 'อัตราการเผาผลาญพลังงานพื้นฐานคือพลังงานทั้งหมดที่ร่างกายบริโภคในหนึ่งวันเมื่อร่างกายอยู่ในสภาวะพัก, ไม่ได้รับผลกระทบจากการออกกำลังกาย,วัตถุทางกายภาพ,ความวิตกกังวล,การเปลี่ยนแปลงอุณหภูมิภายนอก ฯลฯ',
            },
            {
                title: 'อัตราส่วนของรอบเอวต่อรอบสะโพก',
                msg: 'อัตราส่วนระหว่างเอวและสะโพก, เป็นตัวบ่งชี้ที่สำคัญในการกำหนดความอ้วนที่ศูนย์กลาง',
            },
            {
                title: 'ค่าดัชนีมวลกาย',
                msg: 'ค่าดัชนีมวลกาย ใช้สำหรับประเมินความอ้วนที่แสดงออกของร่างกาย และเป็นมาตรฐานทั่วไปในการวัดระดับความอ้วน',
            },
            {
                title: 'เปอร์เซ็นไขมันในร่างกาย',
                msg: 'เปอร์เซ็นไขมันในร่างกาย เป็นการวัดองค์ประกอบของร่างกาย บ่งบอกว่าร้อยละเท่าไหร่ของน้ำหนักร่างกายเป็นไขมัน',
            },
            {
                title: 'ระดับไขมันในช่องท้อง',
                msg: 'ไขมันในช่องท้องเป็นตัวบ่งชี้ความอ้วนที่ซ่อนอยู่ที่สำคัญ',
            },
            {
                title: 'ปริมาณน้ำในเซลส์',
                msg: 'ปริมาณน้ำในเซลส์: เป็นของเหลวที่อยู่ในเซลล์ร่างกาย และเป็นส่วนประกอบพื้นฐานของโปรโตพลาสม์',
            },
            {
                title: 'ปริมาณน้ำนอกเซลส์',
                msg: 'ปริมาณน้ำนอกเซลส์: โดยปกติจะหมายถึงของเหลวนอกเซลล์ร่างกาย, รวมทั้งพลาสมาและของเหลวระหว่างเซลล์อินเตอร์สเตเชียล',
            },
            {
                title: 'อายุเมแทบอลิก',
                msg: 'อายุเมแทบอลิกหมายถึงประสิทธิภาพของร่างกายและฟังก์ชันสุขภาพที่เกี่ยวข้องกับเมแทบอลิซึม',
            },
        ],
        // 节段
        segment: {
            fat: 'การวิเคราะห์ไขมันแบบแบ่งส่วน',
            muscle: 'การวิเคราะห์กล้ามเนื้อแบบแบ่งส่วน',
            right: 'ขวา',
            left: 'ซ้าย',
            standard: 'ปกติ',
            lowStandard: 'ต่ำกว่า',
            superStandard: 'สูงกว่า',
            peel: '(น้ำหนักภาชนะ)',
        },
    },
    // 体态
    shape: {
        title: 'ภาพรวมการประเมินร่างกาย',
        titles: 'ประเมินท่าทาง',
        status: 'สภาพร่างกายปัจจุบันของคุณ',
        suggest: 'ข้อเสนอแนะ',
        possibility: ', มีความเป็นไปได้ของ',
        models: {
            front: 'ด้านหน้า',
            left: 'ด้านซ้าย',
            right: 'ด้านขวา',
            top: 'ด้านหลัง',
            low: 'ด้านล่าง',
            high: 'ด้านบน',
            normal: 'ปกติ',
        },
        item: {
            deviate: 'ค่า',
            result: 'คำอธิบาย',
            normal: 'ปกติ',
			keAbnormal: 'อาจมีความผิดปกติ',
            abnormal: 'ไม่ปกติ',
            head: 'ท่าศีรษะไปข้างหน้า',
            headSlant: 'ศรีษะเอียง',
            roundShoulderLeft: 'ไหล่ห่อ(ซ้าย)',
            roundShoulderRight: 'ไหล่ห่อ(ขวา)',
            highLowShoudler: 'ไหล่ไม่เท่ากัน',
            pelvis: 'การเปลี่ยนแปลงกระดูกเชิงกรานด้านหน้า/ด้านหลัง',
            leftKneeCheck: 'การวิเคราะห์หัวเข่าด้านซ้าย',
            rightKneeCheck: 'การวิเคราะห์หัวเข่าด้านขวา',
            leg: 'รูปร่างของขา / ลักษณะของขา',
            leftLeg: 'ขาซ้าย:',
            rightLeg: 'ขาขวา:',
        },
        // 定义解读
        explain: [
            {
                title: 'ท่าศีรษะไปข้างหน้า',
                msg: 'มุมระหว่างจุดที่หูด้านซ้ายและเส้นที่เชื่อมโยงจุดกลางของคอและเส้นกลางด้านข้าง',
            },
            {
                title: 'ศรีษะเอียง',
                msg: 'มุมระหว่างจุดกลางของหัวด้านหน้าและจุดกลางของคอและเส้นกลางด้านหน้า',
            },
            {
                title: 'ไหล่ห่อ(ซ้าย)',
                msg: 'มุมระหว่างเส้นสะบักล่างซ้ายกับเส้นแทรกไหล่',
            },
            {
                title: 'ไหล่ห่อ(ขวา)',
                msg: 'มุมระหว่างเส้นสะบักล่างขวากับเส้นแทรกไหล่',
            },
            {
                title: 'ไหล่ไม่เท่ากัน',
                msg: 'ระยะทางแนวตั้งระหว่างจุดไหล่ซ้ายและขวาที่หลัง',
            },
            {
                title: 'การเปลี่ยนแปลงกระดูกเชิงกรานด้านหน้า/ด้านหลัง',
                msg: 'มุมภายในของเส้นที่เชื่อมจุดกลางของคอ, จุดกระดูกสะโพก, และจุดข้อเท้าด้านซ้าย',
            },
            {
                title: 'การวิเคราะห์หัวเข่าด้านซ้าย',
                msg: 'มุมภายในของเส้นที่เชื่อมจุดกระดูกสะโพก, ข้อเข่า, และจุดข้อเท้าด้านซ้าย',
            },
            {
                title: 'การวิเคราะห์หัวเข่าด้านขวา',
                msg: 'มุมภายในของเส้นที่เชื่อมจุดกระดูกสะโพก, ข้อเข่า, และจุดข้อเท้าด้านขวา',
            },
            {
                title: 'รูปร่างของขา / ลักษณะของขา',
                msg: 'มุมภายในของเส้นที่เชื่อมจุดกระดูกสะโพก, ข้อเข่า, และจุดข้อเท้าที่ด้านหน้าของขาซ้ายและขวา',
            },
        ],
        exception: [
            {
                title: 'มีแนวโน้มล้มทางฝั่งซ้าย',
                abnormal: '',
            },
            {
                title: 'มีแนวโน้มล้มทางฝั่งขวา',
                abnormal: '',
            },
            {
                title: 'สูงทางฝั่งซ้าย',
                abnormal: '',
            },
            {
                title: 'สูงทางฝั่งขวา',
                abnormal: '',
            },
            {
                title: 'การเอียงสะโพกด้านหลัง',
                abnormal: '',
            },
            {
                title: 'การเอียงสะโพกด้านหน้า',
                abnormal: '',
            },
            {
                title: 'การยืดหยุดข้อเข่าฝั่งซ้าย',
                abnormal: '',
            },
            {
                title: 'การเอนหน้าข้อเข่าฝั่งซ้าย',
                abnormal: '',
            },
            {
                title: 'การยืดหยุดข้อเข่าฝั่งขวา',
                abnormal: '',
            },
            {
                title: 'การเอนหน้าข้อเข่าฝั่งขวา',
                abnormal: '',
            },
            {
                title: 'ขารูปตัว K',
                abnormal: '',
            },
            {
                title: 'ขารูปตัว D',
                abnormal: '',
            },
            {
                title: 'ขารูป X',
                abnormal: '',
            },
            {
                title: 'ขารูป O',
                abnormal: '',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'ระยะห่างในแนวดิ่งระหว่างหูกับแนวเส้นแรงโน้มถ่วงของร่างกาย',
            },
            {
                msg: 'มุมระหว่างเส้นที่เชื่อมหูทั้งสองข้างกับระนาบแนวนอน',
            },
            {
                msg: 'ระยะห่างในแนวดิ่งระหว่างปุ่มกระดูกหัวไหล่ซ้ายกับแนวเส้นแรงโน้มถ่วง',
            },
            {
                msg: 'ระยะห่างในแนวดิ่งระหว่างกระดูกสะบักขวากับแนวเส้นแรงโน้มถ่วง',
            },
            {
                msg: 'ความแตกต่างของเส้นที่เชื่อมระหว่างโคนคอกับกระดูกสะบักทั้งสองข้าง',
            },
            {
                msg: 'ระยะห่างในแนวดิ่งระหว่างข้อสะโพกกับแนวเส้นแรงโน้มถ่วงของร่างกาย',
            },
            {
                msg: 'มุมที่เกิดจากข้อเท้า จุด 1 ซม. ใต้ข้อเข่าซ้าย และข้อสะโพกในระนาบซาจิตทัล',
            },
            {
                msg: 'มุมที่เกิดจากข้อเท้า จุด 1 ซม. ใต้ข้อเข่าขวา และข้อสะโพกในระนาบซาจิตทัล',
            },
            {
                msg: 'มุมระหว่างข้อสะโพก ข้อเข่า และข้อเท้าทั้งสองข้างในระนาบโคโรนัล',
            },
        ],
        item: {
            pelvis: 'การเอียงของกระดูกเชิงกรานไปด้านหน้า',
        },
    },
    // 围度信息
    girth: {
        title: 'ส่วนรอบ',
        present: '(ปัจจุบัน)',
        noHistory: 'ไม่มีข้อมูล',
        tpl: [
            {
                title: 'คอ',
                key: 'neckGirth',
            },
            {
                title: 'ต้นแขนซ้าย',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'ต้นแขนขวา',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'หน้าอก',
                key: 'bustGirth',
            },
            {
                title: 'เอวสูง',
                key: 'waistGirth',
            },
            {
                title: 'เอวกลาง',
                key: 'midWaistGirth',
            },
            {
                title: 'สะโพก',
                key: 'hipGirth',
            },
            {
                title: 'ต้นขาซ้าย',
                key: 'leftThighGirth',
            },
            {
                title: 'ขาหนีบซ้ายกลาง',
                key: 'leftMidThighGirth',
            },
            {
                title: 'เส้นรอบวงขั้นต่ำของต้นขาซ้าย',
                key: 'leftMinThighGirth',
            },
            {
                title: 'ต้นขาขวา',
                key: 'rightThighGirth',
            },
            {
                title: 'ขาหนีบขวากลาง',
                key: 'rightMidThighGirth',
            },
            {
                title: 'เส้นรอบวงขั้นต่ำของต้นขาขวา',
                key: 'rightMinThighGirth',
            },
            {
                title: 'น่องซ้าย',
                key: 'leftCalfGirth',
            },
            {
                title: 'น่องขวา',
                key: 'rightCalfGirth',
            },
            {
                title: 'เอวต่ำ',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'ฟังก์ชันของไหล่',
        model: {
            val: 'ค่า',
            scope: 'สถานที่ที่มีกิจกรรมสูงสุด',
            diff: 'เน็ต',
        },
        normal: 'ไม่พบความผิดปกติที่สังเกตได้.',
        normalAll: 'ไม่พบความผิดปกติที่สำคัญในการทำงานของไหล่.',
        conclusion: 'สรุป',
        analyse: 'การวิเคราะห์: ',
        suggest: 'ข้อเสนอ: ',
        cause: 'กรุณาขอคำแนะนำจากผู้เชี่ยวชาญเพื่อรายละเอียด.',
    },
    reportTips: {
        title: 'กรุณาติดต่อผู้ดูแลระบบหากคุณต้องการลบรายงาน',
        btnMsg: 'ตกลง',
        alaryTips: 'รายงานนี้ได้ถูกลบโดยผู้ดูแลระบบแล้ว จะข้ามไปยังรายงานถัดไป!',
    },
    saveQr: {
        title: 'รายงานการสแกนร่างกาย 3D ของ Visbody',
        btnMsg: 'กดค้างภาพเพื่อบันทึกลงบนโทรศัพท์ของคุณ',
        tips: 'หากคุณพบว่าไม่สามารถบันทึกได้ กรุณาไปที่ [การตั้งค่า] และเปิดสิทธิ์ที่เกี่ยวข้อง',
    },
    vanCalendar: {
        end: 'End',
        start: 'Start',
        title: 'Calendar',
        startEnd: 'Start/End',
        weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthTitle: function monthTitle(year, month) {
            return year + '/' + month
        },
        rangePrompt: function rangePrompt(maxRange) {
            return 'Choose no more than ' + maxRange + ' days'
        },
    },
    share: {
        title: 'โปรดคัดลอกลิงค์ด้านล่างและแชร์',
        btnMsg: 'คัดลอก',
    },
    ...zhLocale,
    ...agrZh,
}
