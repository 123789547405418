import agrEn from './agr-en'
export default {
    // ai报告相关
    ai: {
        report: "Análisis Informe IA",
        train: "Plan Formativo",
        loading: "Cargando, por favor espere...",
        loadingError1: "No se pudo cargar",
        loadingError2: "Lo sentimos. Intente más tarde.",
    },
    login: {
        loginAndRegister: 'Registrarse / Iniciar sesión',
        registerAndLogin: 'Iniciar sesión / Registrarse',
        userLogin: 'Iniciar sesión',
        title: 'La cuenta creada por correo electrónico y número de teléfono son independientes entre sí, por favor',
        mobileLogin: 'Teléfono',
        emailLogin: 'Correo electrónico',
        email: 'E-mail',
        loginBtn: 'Iniciar sesión',
        autoLogin: 'Recordarme',
        retransmission: 'sí',
        formList: [
            {
                label: 'Ingrese su número de teléfono',
                placeholder: 'Enviar',
            },
            {
                label: 'Código de verificación',
                placeholder: 'Ingrese el código de verificación',
            },
            {
                label: 'Por favor ingrese su correo electrónico.',
            },
        ],
        btn: {
            submit: 'Crear una cuenta',
            title: 'Para tener datos precisos, es importante ingresar la altura correcta, género y edad.',
        },
        rule: {
            phone: {
                requiredMsg: 'Formato incorrecto del número telefónico.',
                patternMsg: 'El teléfono no está registrado, cree su cuenta.',
            },
            authCode: {
                requiredMsg: 'Código de verificación incorrecto, vuelva a escribirlo.',
            },
            email: {
                requiredMsg: 'Formato incorrecto del correo electrónico.',
                patternMsg: 'El correo electrónico no está registrado, cree su cuenta.',
            },
        },
        codeSuccess: 'El código de verificación ha sido enviado, por favor revise su correo electrónico o mensaje.',
        success: 'Envío exitoso.',
        error: 'Error en el envío.',
        loginSuccess: 'Inicio de sesión exitoso.',
        account: {
            accountTitle: 'Iniciar sesión',
            registerTitle: 'Ingresar',
            resetTitle: 'Configurar contraseña',
            password: 'Contraseña',
            placeholder: 'Por favor, ingrese la contraseña',
            codeLogin: 'Iniciar sesión con código de verificación',
            accountLogin: 'Iniciar sesión con contraseña',
            forgotPassword: '¿Olvidaste tu contraseña?',
            registerTips:[
                '¿No tienes una cuenta?', 
                'Ingresar', 
                'La cuenta ya existe'
            ],
            checkRule: [
                'De 8 a 20 caracteres',
                'Debe incluir al menos una letra mayúscula',
                'Debe incluir al menos una letra minúscula',
                'Debe incluir al menos un número', 
                'Las contraseñas no coinciden'
            ],
            errorTips: [
                'Contraseña incorrecta', 
                'Error al iniciar sesión', 
                'La cuenta no existe'
            ],
            reset:[
                'Restablecer contraseña', 
                "Siguiente",
                'La nueva contraseña no puede ser igual a la anterior', 
                'Omitir',
                'Contraseña configurada con éxito'
            ],
            setPasswordTips:'La contraseña debe tener entre 8 y 20 caracteres e incluir letras mayúsculas, letras minúsculas y números.',
            confirmPassword: "Confirmar contraseña",
        }
    },
    register: {
        mobileRegister: 'Teléfono',
        emailRegister: 'Correo electrónico',
        enroll: 'Número celular ya registrado.',
        search: 'Buscar',
        title: 'La cuenta creada por correo electrónico y número telefónico son independientes entre sí, por favor acepte los términos y condiciones para continuar con la creación de la cuenta.',
        needRead: {
            agree: 'Acepto',
            privacy: '(Política De Privacidad)',
        },
        register: 'Ingresar',
        check: "Verificar 'Privacidad Del Usuario'",
        newUser: 'Registrarse',
        form: {
            labelList: ['Nombre', '* Género', '* Altura', '* Edad'],
        },
        placeholder: ['Por favor ingrese su nombre', 'Por favor ingrese su altura', 'Por favor ingrese su edad'],
        unit: ['Métrico', 'Imperial', ''],
        sexOptions: {
            male: 'Masculino',
            female: 'Femenino',
        },
        btn: 'Enviar',
        rules: {
            metricheight: ["El rango de altura es 2'3''-6'7'', ingréselo nuevamente."],
            heightMsg: ['El rango de altura es 70-200cm., ingréselo nuevamente'],
            ageMsg: ['El rango de edad es 10-99 años., ingréselo nuevamente'],
            agePointMsg: [
                'Llene un valor no entero: La edad solo puede ser un número entero, por favor ingrese la edad correcta.',
            ],
        },
        success: 'Registro exitoso.',
        userMessage: 'Ingrese el nombre de usuario',
        verify: 'Ingrese el código de verificación de 4 dígitos',
        errorMsg: 'El formato de altura no admite decimales, ingréselo nuevamente.',
        enrollEmail: 'El correo electrónico está registrado.',
        abnormalMsg: 'Anomalía de registro',
    },
    model: {
        loading: 'Cargando... Por favor espere un momento',
        modelLoading: 'Carga del modelo 3D',
        tabPane: [
            {
                title: 'Tecnología Deep Real Sense',
                msg: 'Recopila con precisión los detalles locales del cuerpo a través del escaneo 3D, asegura la precisión a nivel milimétrico en la medición y presenta claramente los cambios en la forma corporal después del ejercicio.',
            },
            {
                title: 'Algoritmo BDA para composición corporal',
                msg: 'Visbody utiliza el sistema más avanzado de medición de composición corporal: algoritmo BDA para determinar la composición corporal. Este método basado en el volumen corporal evalúa con mayor precisión los riesgos para la salud que trae consigo la obesidad.',
            },
            {
                title: 'Factores que causan errores',
                msg: 'La ropa suelta o algunas decoraciones sobre/en el cuerpo pueden causar errores; use prendas ajustadas o reduzca las prendas si desea obtener datos más precisos.',
            },
            {
                title: 'Obtenga su informe',
                msg: 'Se recomienda marcar como favorita esta página web para una fácil visualización del informe en el futuro. También puede enviar este informe a su correo electrónico para guardarlo posteriormente.',
            },
        ],
        collect: ['Por favor marque esta página como favorita, Fácil revisión del informe en el futuro', ''],
        know: 'Entendido',
        reminder: [
            'Recordatorio amable',
            'Su modelo avatar 3D ha sido generado; deslice hacia izquierda y derecha para rotar el modelo.',
            "Se ha generado su informe y se puede ver haciendo clic en 'Ver Informe'.",
            'Su evaluación física no tuvo éxito, por favor revise el informe de composición corporal.',
            'Su medición de composición corporal no tuvo éxito, por favor revise el informe de evaluación postural.',
            'Su medición no tuvo éxito, por favor vaya al dispositivo y pruebe nuevamente.',
            'La medición de la composición corporal no tuvo éxito; vaya al dispositivo y pruebe nuevamente.',
            'La evaluación postural no tuvo éxito; vaya al dispositivo y pruebe nuevamente.',
        ],
        btn: {
            viewReport: 'Ver Informe',
            bodyReport: 'Ver Informe De Composicion Corporal',
            postureReport: 'Ver Informe De Evaluacion Postural',
            lastReport: 'Ver Último Informe',
            loading: 'Cargando...',
        },
    },
    report: {
        btn: {
            download: 'Descargar El Informe',
            send: 'Enviar El Informe',
            report: 'Informe',
        },
        reportSuccess: '¡Éxito!',
        tabPane: {
            labelList: [
                'Tiempo De Medida',
                'Artículo',
                'Composicion Corporal',
                'Evaluacion Postural',
                'Función Del Hombro',
            ],
        },
        sendEmail: 'Correo electrónico:',
        requiredMsg: 'Ingrese su dirección de correo electrónico',
        patternMsg: 'Por favor ingrese una dirección válida de correo electrónico',
        send: 'Enviar',
        download: 'Descargar El Informe',
        sendReport: 'Enviar El Informe',
    },
    sidebar: {
        successMsg: {
            sex: 'Género cambiado exitosamente',
            height: 'Altura cambiada exitosamente',
            age: 'Edad cambiada exitosamente',
        },
        logOut: 'Cerrar sesión',
        personal: 'Centro personal',
        cut: 'Cambiar unidades/idiomas',
        unit: ['Métrico (kg, cm)', 'Imperial (ft, in, lb)'],
        read: {
            reading: 'Leer',
            privacy: '(Política de privacidad)',
        },
        setting: 'Configuración',
        form: {
            labelList: ['Teléfono', 'Unidades', 'Idiomas', 'Nombre', 'Género', 'Altura', 'Edad'],
        },
        placeholder: ['Cambiar tu nombre de usuario', 'Nuevo género', 'Altura actualizada', 'Edad actualizada'],
        btn: {
            cancelBtn: 'Cancelar',
            accomplishBtn: 'Completar',
            confirmBtn: 'Confirmar',
        },
        dialog: [
            'El género es una de las bases importantes para la medición de la composición corporal. Los datos medidos después de la modificación serán diferentes a los datos anteriores. ¿Por favor confirma si deseas cambiarlo?',
            'La altura es una de las bases importantes para la medición de la composición corporal. Los datos medidos después de la modificación serán diferentes a los datos anteriores. ¿Por favor confirma si deseas cambiarlo?',
            'La edad es una de las bases importantes para la medición de la composición corporal. Los datos medidos después del cambio serán diferentes a los previos. Por favor confirma si deseas modificarlos.',
        ],
        rules: {
            metricheight: ["Por favor ingresa el rango correcto para altura (2' 3'' hasta 6'7'')"],
            heightMsg: ['Por favor ingresa un valor entre 70cm - 200cm para altura.'],
            ageMsg: [
                'Ingresa un valor entre 10 y 99 años para edad.',
                'Ingresaste un número no entero, por lo que se requiere ingresar solo números enteros como edad.',
            ],
        },
        heightMsg: 'Vuelve a elegir tu altura porfavor.',
        ageMsg: 'Vuelve a elegir tu edad porfavor.',
        ModelBinding: 'Parece que alguien ya escaneó el código, ¡porfavor mide de nuevo!',
        ReportEmpty: {
            title: 'Aún no tienes ningún informe.',
            desc: '¡Ven al escáner corporal 3D Visbody y prueba de nuevo!',
        },
        changeSort: 'Cambio de Informe de Producto.',
        setPassword: 'Configurar/Cambiar contraseña',
        describe:
            'Después de cambiar el modelo del producto, se mostrarán todos los informes de medición bajo este modelo.',
        describeBtn:
            'Después de cambiar el modelo del producto, será redirigido al último informe de medición bajo este modelo.',
        descReport: 'Cambio exitoso en el Informe del Producto.',
    },
    modelBinding: {
        desc: 'Parece que alguien ya escaneó el código, ¡porfavor mide de nuevo!',
    },
    reportEmpty: {
        title: 'Aún no tienes ningún informe.',
        desc: '¡Ven al escáner corporal 3D Visbody y prueba de nuevo!',
    },
    userInfo: {
        height: 'Altura:',
        age: 'Edad:',
        unit: '',
        weight: 'peso',
    },
    assessment: {
        great: 'Excelente',
        good: 'Bueno',
        normall: 'Normal',
        weak: 'Débil',
    },
    mass: {
        title: 'Resumen del análisis de la composición corporal',
        titles: 'Composición Corporal',
        springFrame: '',
        massFrame: 'No hay datos medidos para este día.',
        contrast: 'Elige un reporte para comparar.',
        contrastReport: 'Elige un reporte para comparar.',
        noRecord: 'Sin registros',
        score: 'Puntuación',
        status: 'Tu estado actual en cuanto a la composición corporal:',
        WT: 'peso',
        PBF: 'porcentaje de grasa corporal',
        measure: {
            value: 'Valores',
            standardRange: 'rango estándar',
            comparedWithLastLime: 'Neto',
            comparedNet: 'Comparado con la última puntuación',
            WT: 'Peso',
            FFM: 'Masa muscular magra',
            BFM: 'Masa grasa',
            LM: 'Masa muscular',
            TBW: 'Agua Corporal Total (ACT)',
            SM: 'MMI (masa muscular esquelética)',
            PROTEIN: 'Proteína',
            TM: 'Sales inorgánicas',
            BMR: 'Metabolismo basal',
            WHR: 'Índice cintura-cadera',
            BMI: 'IMC',
            PBF: 'Porcentaje de grasa corporal',
            VFG: 'Nivel De Grasas Viscerales',
            unit: 'kg',
            BMRUnit: 'kcal/día',
        },
        explain: [
            {
                title: 'Peso',
                msg: 'El peso es la suma del agua corporal, proteínas, sales inorgánicas y peso total del cuerpo.',
            },
            {
                title: 'Masa muscular magra',
                msg: 'La masa muscular magra es el peso total del cuerpo sin grasa.',
            },
            {
                title: 'Masa grasa',
                msg: 'La masa grasa es la suma de las grasas subcutáneas, viscerales y musculares.',
            },
            {
                title: 'Masa muscular',
                msg: 'La masa magra suave es la masa muscular magra, que incluye músculo esquelético, músculo liso y músculo cardíaco.',
            },
            {
                title: 'Agua Corporal Total (ACT)',
                msg: 'La mayor parte del cuerpo humano es agua con un porcentaje de 50%-70% del peso corporal. El agua corporal se encuentra principalmente en las células humanas y los fluidos corporales, la mayoría de los cuales están en las células musculares.',
            },
            {
                title: 'MMI (masa muscular esquelética)',
                msg: 'La masa muscular esquelética, también conocida como músculo estriado, es un tipo de músculo adherido a los huesos. Este dato contiene la cantidad de Masa Muscular Esquelética.',
            },
            {
                title: 'Proteína',
                msg: 'La proteína es una sustancia sólida con amoníaco que existe en todas las células del cuerpo humano. Es el componente principal de la masa muscular.',
            },
            {
                title: 'Sales inorgánicas',
                msg: 'El cuerpo humano está compuesto de materia orgánica, materia inorgánica y agua. La materia inorgánica aquí son sales inorgánicas que representan el 5% del peso corporal.',
            },
            {
                title: 'Metabolismo basal',
                msg: 'El metabolismo basal se refiere a la cantidad total de energía que el cuerpo humano consume en un día cuando está despierto y en reposo, sin verse afectado por el ejercicio, la comida, el estrés nervioso o los cambios de temperatura externos.',
            },
            {
                title: 'Índice cintura-cadera',
                msg: 'Es la relación entre la circunferencia de la cintura y la cadera; es un indicador importante para determinar obesidad central.',
            },
            {
                title: 'IMC',
                msg: 'El metabolismo basal se refiere a la cantidad total de energía que el cuerpo humano consume en un día cuando está despierto y en reposo, sin verse afectado por el ejercicio, la comida, el estrés nervioso o los cambios de temperatura externos.',
            },
            {
                title: '% Graso Corporal',
                msg: 'Es una medida de composición corporal que indica cuánto del peso corporal corresponde a grasa.',
            },
            {
                title: 'Nivel de Grasa Visceral',
                msg: 'La grasa visceral es un indicador importante para evaluar obesidad oculta.',
            },
            {
                title: 'ICW',
                msg: 'ICW: Es el fluido corporal contenido en las células del cuerpo y es el componente básico del protoplasma.',
            },
            {
                title: 'ECW',
                msg: 'ECW: Por lo general se refiere a los líquidos extracelulares del cuerpo, incluyendo plasma e intersticial entre los vasos sanguíneos y las células tisulares.',
            },
            {
                title: 'Edad Metabólica',
                msg: 'La edad metabólica se refiere al rendimiento del cuerpo y sus funciones de salud relacionadas con el metabolismo.',
            },
        ],
        segment: {
            fat: 'Análisis Segmentario De Grasas',
            muscle: 'Análisis Segmentario De Magro',
            right: 'Derecha',
            left: 'Izquierda',
            standard: 'Normal',
            lowStandard: 'Bajo',
            superStandard: 'Alto',
            peel: '(Tare)',
        },
    },
    shape: {
        title: 'Resumen De Evaluación Corporal:',
        titles: 'Postura',
        status: 'Su condición física actual,',
        suggest: 'Sugerencia,',
        possibility: 'Hay una posibilidad de,',
        models: {
            front: 'Frente',
            left: 'Lado Izquierdo',
            right: 'Lado Derecho',
            top: 'Espalda',
            low: 'Bajo',
            high: 'Alto',
            normal: 'Normal',
        },
        item: {
            deviate: 'Valores',
            result: 'Explicación',
            normal: 'Normal',
			keAbnormal: 'Posible anormalidad',
            abnormal: 'Anormal',
            head: 'Postura de cabeza adelantada',
            headSlant: 'Inclinación de la cabeza',
            roundShoulderLeft: 'Postura de hombros redondeados (lado izquierdo)',
            roundShoulderRight: 'Postura de hombros redondeados (lado derecho)',
            highLowShoudler: 'Hombros desiguales',
            pelvis: 'Movimiento Anterior/Posterior de la Pelvis',
            leftKneeCheck: 'Evaluación de rodilla izquierda',
            rightKneeCheck: 'Evaluación de rodilla derecha',
            leg: 'Forma de las piernas:',
            leftLeg: 'Pierna izquierda:',
            rightLeg: 'Pierna derecha:',
        },
        explain: [
            {
                title: 'Postura con cabeza adelantada',
                msg: 'El ángulo entre el punto de la oreja del lado izquierdo y la línea que conecta el centro del cuello y la línea media en el costado.',
            },
            {
                title: 'Inclinación de cabeza',
                msg: 'El ángulo entre el punto medio de la parte frontal de la cabeza, el centro del cuello y la línea media frontal.',
            },
            {
                title: 'Postura con hombros redondeados (lado izquierdo)',
                msg: 'El ángulo entre la línea que conecta el punto más alto del lado izquierdo de la espalda y la tangente al hombro.',
            },
            {
                title: 'Postura con hombros redondeados (lado derecho)',
                msg: 'El ángulo entre la línea que conecta el punto más alto del lado derecho de la espalda y su tangente al hombro.',
            },
            {
                title: 'Hombros desiguales',
                msg: 'La distancia vertical entre los puntos del hombro izquierdo y derecho en la espalda.',
            },
            {
                title: 'Movimiento Anterior/Posterior de la Pelvis',
                msg: 'Ángulo incluido formado por las líneas que conectan los puntos centrales del cuello, hueso de cadera, y tobillo en el lado izquierdo.',
            },
            {
                title: 'Evaluación rodilla izquierda',
                msg: 'Ángulo incluido formado por las tres líneas que conectan los puntos hueso de cadera, articulación de rodilla, y tobillo en el lado izquierdo.',
            },
            {
                title: 'Evaluación rodilla derecha',
                msg: 'Ángulo incluido formado por las tres líneas que conectan los puntos hueso de cadera, articulación de rodilla, y tobillo en el lado derecho.',
            },
            {
                title: 'Forma pierna',
                msg: 'Ángulo incluido formado por las líneas que unen los puntos hueso de cadera, articulación rodilla, y tobillo en la parte frontal de las piernas izquierda y derecha.',
            },
        ],
        exception: [
            {
                title: 'Inclinado hacia el lado izquierdo',
                abnormal: 'inclinado hacia el lado izquierdo',
            },
            {
                title: 'Inclinado hacia el lado derecho',
                abnormal: 'inclinado hacia el lado derecho',
            },
            {
                title: 'Alto a la izquierda',
                abnormal: 'alto a la izquierda',
            },
            {
                title: 'Alto a la derecha',
                abnormal: 'alto a la derecha',
            },
            {
                title: 'Inclinación pélvica posterior',
                abnormal: 'inclinación pélvica posterior',
            },
            {
                title: 'Inclinación pélvica anterior',
                abnormal: 'inclinación pélvica anterior',
            },
            {
                title: 'Hiperextensión de rodilla izquierda',
                abnormal: 'hiperextensión de rodilla izquierda',
            },
            {
                title: 'Flexión de rodilla izquierda',
                abnormal: 'flexión de rodilla izquierda',
            },
            {
                title: 'Hiperextensión de rodilla derecha',
                abnormal: 'hiperextensión de rodilla derecha',
            },
            {
                title: 'Flexión de rodilla derecha',
                abnormal: 'flexión de rodilla derecha',
            },
            {
                title: 'Piernas en forma K',
                abnormal: 'piernas en forma K',
            },
            {
                title: 'Piernas en forma D',
                abnormal: 'piernas en forma D',
            },
            {
                title: 'Piernas con forma X',
                abnormal: 'piernas con forma X',
            },
            {
                title: 'Piernas con forma O',
                abnormal: 'piernas con forma O',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'Distancia vertical entre las orejas y la línea de gravedad corporal',
            },
            {
                msg: 'Ángulo entre la línea que conecta ambas orejas y el plano horizontal',
            },
            {
                msg: 'Distancia vertical entre el acromion izquierdo y la línea de gravedad',
            },
            {
                msg: 'Distancia vertical entre el acromion derecho y la línea de gravedad',
            },
            {
                msg: 'Diferencia en las líneas que conectan la base del cuello y los acromiones en ambos lados',
            },
            {
                msg: 'Distancia vertical entre la articulación de la cadera y la línea de gravedad corporal',
            },
            {
                msg: 'Ángulo formado por el tobillo, un punto 1 cm inferior a la rodilla izquierda y la cadera en el plano sagital',
            },
            {
                msg: 'Ángulo formado por el tobillo, un punto 1 cm inferior a la rodilla derecha y la cadera en el plano sagital',
            },
            {
                msg: 'Ángulo entre las articulaciones bilaterales de cadera, rodilla y tobillo en el plano coronal',
            },
        ],
        item: {
            pelvis: 'Anteversión pélvica',
        },
    },
    girth: {
        title: 'Circunferencias',
        present: '(Presente)',
        tpl: [
            {
                title: 'Circunferencia del cuello',
                key: 'girth.tpl.0.key',
            },
            {
                title: 'Brazo izquierdo',
                key: 'girth.tpl.1.key',
            },
            {
                title: 'Brazo derecho',
                key: 'girth.tpl.2.key',
            },
            {
                title: 'Busto',
                key: 'girth.tpl.3.key',
            },
            {
                title: 'Cintura alta',
                key: 'girth.tpl.4.key',
            },
            {
                title: 'Cintura media',
                key: 'girth.tpl.5.key',
            },
            {
                title: 'Línea superior',
                key: 'girth.tpl.6.key',
            },
            {
                title: 'Muslo izquierdo',
                key: 'girth.tpl.7.key',
            },
            {
                title: 'Muslo izquierdo medio',
                key: 'girth.tpl.8.key',
            },
            {
                title: 'Circunferencia mínima del muslo izquierdo',
                key: 'girth.tpl.9.key',
            },
            {
                title: 'Circunferencia del muslo derecho',
                key: 'girth.tpl.10.key',
            },
            {
                title: 'Muslo derecho medio',
                key: 'girth.tpl.11.key',
            },
            {
                title: 'Circunferencia mínima del muslo derecho',
                key: 'girth.tpl.12.key',
            },
            {
                title: 'Perímetro de la pantorrilla izquierda',
                key: 'girth.tpl.13.key',
            },
            {
                title: 'Perímetro de la pantorrilla derecha',
                key: 'girth.tpl.14.key',
            },
            {
                title: 'Cintura baja',
                key: 'girth.tpl.15.key',
            },
        ],
    },
    shoulder: {
        title: 'Función del Hombro',
        model: {
            val: 'Valores',
            scope: 'Ubicación de Actividad Máxima',
            diff: 'Resultado',
        },
        normal: 'No se detectaron anomalías evidentes',
        normalAll: 'No se han detectado anomalías significativas en la función del hombro',
        conclusion: 'Conclusión',
        analyse: 'Análisis:',
        suggest: 'Sugerencia:',
        cause: 'Por favor, consulte a expertos para obtener más detalles.',
    },
    reportTips: {
        title: 'Si necesita eliminar el informe, por favor contacte al administrador del dispositivo.',
        btnMsg: 'OK',
        alaryTips:
            'Este informe de medición ha sido eliminado por el administrador. ¡Serás redirigido al siguiente informe!',
    },
    saveQr: {
        title: 'Informe del Escáner Corporal 3D Visbody',
        btnMsg: 'Mantenga presionada la imagen para guardarla en su teléfono móvil.',
        tips: 'Si no puede guardarla, vaya a [Configuración] y abra los permisos correspondientes.',
    },
    share: {
        title: 'Por favor, copia el enlace de abajo y compártelo.',
        btnMsg: 'Copiar',
    },
    ...agrEn,
}
