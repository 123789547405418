import trLocale from 'element-ui/lib/locale/lang/tr-TR'
import agrEn from './agr-en'
export default {
    // ai报告相关
    ai: {
        report: "YZ Rapor Analizi",
        train: "Eğitim Planı",
        loading: "Yükleniyor...",
        loadingError1: "Yükleme başarısız",
        loadingError2: "Özür dileriz. Lütfen tekrar deneyiniz.",
    },
    // 登录页面
    login: {
        loginAndRegister: 'Kaydol / Giriş Yap',
        registerAndLogin: 'Giriş Yap/Kaydol',
        userLogin: 'Giriş Yap',
        title: 'Mobil ve e-posta hesapları bağımsızdır; lütfen hesap türünüze dikkat edin.',
        mobileLogin: 'Telefon',
        emailLogin: 'E-posta',
        email: 'E-posta',
        loginBtn: 'Giriş Yap',
        autoLogin: 'Beni hatırla',
        retransmission: 's',
        formList: [
            {
                label: 'Telefon numaranızı girin',
                placeholder: 'Gönder',
            },
            {
                label: 'Doğrulama Kodu',
                placeholder: 'Doğrulama kodunu girin',
            },
            {
                label: 'Lütfen e-postanızı girin',
            },
        ],
        btn: {
            submit: 'Hesap Oluştur',
            title: 'Doğru bilgi çok önemlidir, lütfen dikkatlice doldurun.',
        },
        rule: {
            phone: {
                requiredMsg: 'Geçersiz Telefon Numarası Formatı',
                patternMsg: 'Telefon numarası kayıtlı değil, lütfen yeni bir hesap oluşturun.',
            },
            authCode: {
                requiredMsg: 'Yanlış doğrulama kodu, lütfen tekrar girin.',
            },
            email: {
                requiredMsg: 'Geçersiz e-posta formatı.',
                patternMsg: 'E-posta Kayıtlı Değil, Hesabınızı Oluşturun',
            },
        },
        codeSuccess: 'Doğrulama kodu gönderildi, lütfen kontrol edin.',
        success: 'Başarıyla gönderildi.',
        error: 'Gönderilemedi.',
        loginSuccess: 'Giriş başarılı.',
        account: {
            accountTitle: 'Giriş Yap',
            registerTitle: 'Oturum Aç',
            resetTitle: 'Şifreyi Ayarla',
            password: 'Şifre',
            placeholder: 'Lütfen şifrenizi girin',
            codeLogin: 'Doğrulama kodu ile giriş yapın',
            accountLogin: 'Şifre ile giriş yapın',
            forgotPassword: 'Şifrenizi mi unuttunuz?',
            registerTips:[
                'Henüz bir hesabınız yok mu?', 
                'Oturum Aç', 
                'Hesap zaten mevcut'
            ],
            checkRule: [
                '8-20 karakter',
                'En az bir büyük harf içermelidir',
                'En az bir küçük harf içermelidir',
                'En az bir rakam içermelidir', 
                'Şifreler uyuşmuyor'
            ],
            errorTips: [
                'Şifre hatalı', 
                'Giriş başarısız', 
                'Hesap bulunamadı'
            ],
            reset:[
                'Şifreyi sıfırla', 
                "Sonraki",
                'Yeni şifre, önceki şifreyle aynı olamaz', 
                'Atla',
                'Şifre başarıyla ayarlandı'
            ],
            setPasswordTips:'Şifre 8-20 karakterden oluşmalı ve büyük harf, küçük harf ile rakam içermelidir.',
            confirmPassword: "Parolayı onayla",
        }
    },
    // 注册页面
    register: {
        mobileRegister: 'Telefon',
        emailRegister: 'E-posta',
        enroll: 'Cep Telefonu Numarası Zaten Kayıtlı',
        enrollEmail: 'E-posta kayıtlı',
        search: 'Ara',
        title: 'E-posta ve telefon numarasıyla oluşturulan hesaplar birbirinden bağımsızdır, lütfen',
        needRead: {
            agree: 'Katılıyorum',
            privacy: '(Gizlilik Politikası)》',
        },
        register: 'Oturum Aç',
        check: '"Kullanıcı Gizliliği"ni Kontrol Et',
        newUser: 'Oturum Aç',
        form: {
            labelList: ['İsim', '* Cinsiyet', '* Boy', '* Yaş'],
        },
        placeholder: ['Lütfen adınızı girin', 'Lütfen boyunuzu girin', 'Lütfen yaşınızı girin'],
        unit: ['Metrik', 'İngiliz', ''],
        sexOptions: {
            male: 'Erkek',
            female: 'Kadın',
        },
        btn: 'Gönder',
        rules: {
            metricheight: [`Boy aralığı 2'3" - 6'7", lütfen tekrar girin.`],
            heightMsg: ['Boy aralığı 70 - 200cm, lütfen tekrar girin.'],
            ageMsg: ['Yaş aralığı 10 - 99, lütfen tekrar girin.'],
            agePointMsg: ['Doldurulan tam sayı değil: yaş sadece tam sayı olabilir, lütfen doğru yaşınızı girin'],
        },
        success: 'Kayıt başarılı',
        userMessage: 'Kullanıcı Adını Girin',
        verify: '4 Haneli Doğrulama Kodunu Girin',
        errorMsg: 'Boy formatı ondalıkları desteklemiyor, lütfen tekrar girin.',
    },
    // 模型合成
    model: {
        loading: 'Yükleniyor...Lütfen bir an bekleyin',
        modelLoading: '3D Model Yükleniyor',
        tabPane: [
            {
                title: 'Derin Gerçek Algı Teknolojisi',
                msg: '3D tarama yoluyla vücudun yerel detaylarını doğru bir şekilde toplayın, çevre ölçümünün milimetre düzeyindeki doğruluğunu sağlayın ve egzersiz sonrası vücut şekli değişikliklerini net bir şekilde sunun.',
            },
            {
                title: 'BDA Vücut Kompozisyonu Algoritması',
                msg: 'Visbody, vücut kompozisyonunu belirlemek için en gelişmiş vücut kompozisyonu ölçüm sistemi olan BDA algoritmasını kullanır. Bu hesaplama yöntemi, obezitenin getirdiği sağlık risklerini daha doğru bir şekilde değerlendirecektir.',
            },
            {
                title: 'Hatalara neden olan faktörler',
                msg: 'Gevşek giysiler veya vücutta/üzerinde bulunan bazı süslemeler hatalara neden olacaktır, en doğru verileri almak istiyorsanız tayt giyin veya vücudunuzdaki giysi miktarını azaltın.',
            },
            {
                title: 'Raporunuzu alın',
                msg: "Raporu gelecekte kolayca görüntülemek için web sayfası URL'sini yer imlerine eklemeniz önerilir. Bu raporu daha sonra kaydetmek için posta kutunuza da gönderebilirsiniz.",
            },
        ],
        collect: ['Bu sayfayı yer imlerine ekleyin, rapora gelecekte kolayca erişim sağlayın.', ''],
        know: 'Anladım',
        reminder: [
            'Sıcak İpuçları',
            '3D avatar modeliniz oluşturuldu, modeli döndürmek için sola ve sağa kaydırın.',
            'Raporunuz oluşturuldu ve "Raporu Görüntüle" düğmesine tıklayarak görüntülenebilir.',
            'Vücut değerlendirmeniz başarısız oldu, lütfen vücut kompozisyonu raporunu gözden geçirin.',
            'Vücut kompozisyonu ölçümü başarısız oldu, lütfen cihazda tekrar deneyin.',
            'Ölçümünüz başarısız oldu, lütfen cihaza gidin ve tekrar test edin.',
            'Vücut kompozisyonu ölçümü başarısız oldu, lütfen cihaza gidin ve tekrar test edin.',
            'Postür değerlendirmesi başarısız oldu, lütfen cihaza gidin ve tekrar test edin.',
        ],
        btn: {
            viewReport: 'Raporu Görüntüle',
            bodyReport: 'Kompozisyonu Gör',
            postureReport: 'Vücut Duruşu Raporunu Görüntüle',
            lastReport: 'Son Raporu Görüntüle',
            loading: 'Yükleniyor',
        },
    },
    // 报告
    report: {
        btn: {
            download: 'Raporu İndir',
            send: 'Raporu Gönder',
            report: 'Rapor',
        },
        reportSuccess: 'Başarılı!',
        tabPane: {
            labelList: ['Ölçüm Zamanı', 'Öğe', 'Vücut Kompozisyonu', 'Postür Değerlendirmesi', 'Omuz Fonksiyonu'],
        },
        sendEmail: 'E-posta:',
        requiredMsg: 'E-posta adresinizi girin',
        patternMsg: 'Lütfen geçerli bir e-posta adresi girin',
        send: 'Gönder',
        download: 'Raporu İndir',
        sendReport: 'Raporu Gönder',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: 'Cinsiyet başarıyla değiştirildi',
            height: 'Boy başarıyla değiştirildi',
            age: 'Yaş başarıyla değiştirildi',
        },
        logOut: 'Çıkış Yap',
        personal: 'Kişisel Merkez',
        cut: 'Birimleri/Dilleri Değiştir',
        changeSort: 'Ürün Raporu Değiştirme',
        setPassword: 'Şifreyi Ayarla/Düzenle',
        describe: '*Ürün modelini değiştirdikten sonra, bu model altındaki tüm ölçüm raporları görüntülenecektir.',
        describeBtn:
            'Ürün modelini değiştirdikten sonra, bu model altındaki en son ölçüm raporuna yönlendirileceksiniz.',
        descReport: 'Ürün Raporu Değişim Başarılı',
        unit: ['Metrik (kg, cm)', 'İngiliz (ft, in, lb)'],
        read: {
            reading: 'Oku',
            privacy: '(Gizlilik Politikası)',
        },
        setting: 'Ayar',
        form: {
            labelList: ['Telefon', 'Birimler', 'Diller', 'İsim', 'Cinsiyet', 'Boy', 'Yaş'],
        },
        placeholder: ['Kullanıcı Adınızı Değiştirin', 'Yeni Cinsiyet', 'Güncellenmiş Boy', 'Yaş Güncellendi'],
        btn: {
            cancelBtn: 'İptal',
            accomplishBtn: 'Tamamla',
            confirmBtn: 'Onayla',
        },
        dialog: [
            'Cinsiyet, vücut kompozisyonu ölçümü için önemli bir temeldir. Değişiklikten sonra ölçülen veriler önceki verilerden farklı olacaktır. Değiştirmek istediğinizi onaylar mısınız?',
            'Boy, vücut kompozisyonu ölçümü için önemli bir temeldir. Değişiklikten sonra ölçülen veriler önceki verilerden farklı olacaktır. Değiştirmek istediğinizi onaylar mısınız?',
            'Yaş, vücut kompozisyonu ölçümü için önemli bir temeldir. Değişiklikten sonra ölçülen veriler önceki verilerden farklı olacaktır. Değiştirmek istediğinizi onaylar mısınız?',
        ],
        rules: {
            metricheight: ['Lütfen doğru boy aralığını girin (2" 3"" to 6"7"")'],
            heightMsg: ['Lütfen 70cm - 200cm arasında boy girin'],
            ageMsg: [
                'Lütfen 10 - 99 yaş aralığında yaş girin.',
                'Doldurulan tam sayı değil ,yaş sadece tam sayı olabilir, lütfen doğru yaşınızı girin',
            ],
        },
        heightMsg: 'Lütfen Boyu Yeniden Seçin',
        ageMsg: 'Lütfen Yaşı Yeniden Seçin',
        ModelBinding: 'Görünüşe göre birisi zaten kodu taradı, lütfen tekrar ölçün!',
        ReportEmpty: {
            title: 'Henüz hiç raporunuz yok',
            desc: 'Visbody 3D vücut tarayıcısına gelin ve tekrar test edin!',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: 'Görünüşe göre birisi zaten kodu taradı, lütfen tekrar ölçün!',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: 'Henüz hiç raporunuz yok',
        desc: 'Visbody 3D vücut tarayıcısına gelin ve tekrar test edin!',
        descS30: 'Visbody s30 3D vücut tarayıcısına gelin ve tekrar test edin!',
        descM30: 'Visbody M30 3D vücut tarayıcısına gelin ve tekrar test edin!',
    },
    // 用户信息
    userInfo: {
        height: 'Boy:',
        age: 'Yaş:',
        unit: '',
        weight: 'Vücut Ağırlığı',
    },
    // 体成分
    mass: {
        title: 'Vücut Kompozisyonu Değerlendirmesi Genel Bakış',
        titles: 'Vücut Kompozisyonu',
        springFrame: '',
        massFrame: 'Gün için ölçüm verisi yok',
        contrast: 'Karşılaştırmak için bir rapor seçin',
        contrastReport: 'Karşılaştırmak için bir rapor seçin',
        noRecord: 'Kayıt yok',
        score: 'Skor',
        status: 'Mevcut vücut kompozisyonu durumunuz ',
        WT: 'Vücut Ağırlığı ',
        PBF: 'Vücut Yağ Yüzdesi ',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: 'Değerler',
            standardRange: 'Standart aralık',
            comparedWithLastLime: 'Net',
            comparedNet: 'Son Skora Göre Karşılaştırma',
            current: '本次测量分数',
            WT: 'Vücut Ağırlığı',
            FFM: 'Yağsız Kütle',
            BFM: 'Vücut Yağ Kütlesi',
            LM: 'Kas Kütlesi',
            TBW: 'Vücut Sıvısı',
            SM: 'İskelet Kas Kütlesi',
            PROTEIN: 'Protein',
            TM: 'İnorganik Tuzlar',
            BMR: 'Bazal Metabolik Hız',
            WHR: 'Bel-Kalça Oranı',
            BMI: 'BMI',
            PBF: 'Vücut Yağ Yüzdesi',
            VFG: 'Viseral (İç Organ) Yağ Düzeyi',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: 'Vücut Ağırlığı',
                msg: 'Ağırlık, vücut suyu, protein, inorganik tuz ve vücut ağırlığının toplamıdır.',
            },
            {
                title: 'Yağsız Kütle',
                msg: 'Yağsız Vücut Kütlesi, yağsız toplam vücut ağırlığıdır.',
            },
            {
                title: 'Vücut Yağ Kütlesi',
                msg: 'Vücut Yağ kütlesi, subkutan yağ, viseral yağ ve kas yağının toplamıdır.',
            },
            {
                title: 'Kas Kütlesi',
                msg: 'Kas Kütlesi, insan vücudundaki yağsız  doku grubudur, iskelet kası, düz kas ve kalp kasını içerir.',
            },
            {
                title: 'Vücut Sıvısı',
                msg: "İnsan vücudunun çoğu su olup, vücut ağırlığının %50-%70'ini oluşturur. Ve vücut suyu, çoğunlukla insan hücrelerinde ve vücut sıvılarında bulunur, çoğunluğu kas hücrelerindedir.",
            },
            {
                title: 'İskelet Kas Kütlesi',
                msg: 'İskelet kas kütlesi, ayrıca çizgili kas olarak da bilinir, bir tür kastır ve kemiklere bağlıdır. Bu veri, İskelet Kas miktarını içerir.',
            },
            {
                title: 'Protein',
                msg: 'Protein, tüm insan vücudu hücrelerinde bulunan amonyaklı bir katı maddedir. Kas kütlesinin ana bileşenidir.',
            },
            {
                title: 'İnorganik Tuzlar',
                msg: "İnsan vücudu, organik madde, inorganik madde ve su olmak üzere üç bileşenden oluşur. Buradaki inorganik madde, vücut ağırlığının %5'ini oluşturan inorganik tuzlardır.",
            },
            {
                title: 'Bazal Metabolik Hız',
                msg: 'Bazal Metabolizma oranı, vücut dinlenme durumundayken, egzersiz, fiziksel nesneler, gerginlik, dış sıcaklık değişiklikleri vb. etkilenmeden bir günde tüketilen toplam enerjidir.',
            },
            {
                title: 'Bel-Kalça Oranı',
                msg: 'Belin kalça çevresine oranı, merkezi obeziteyi belirlemek için önemli bir göstergedir.',
            },
            {
                title: 'BMI',
                msg: 'BMI, obezitenin görünümünü değerlendirmek için kullanılır ve vücut yağlılığını ölçmek için ortak bir standarttır.',
            },
            {
                title: 'Vücut Yağ Yüzdesi',
                msg: 'BFP, vücut ağırlığının ne kadarının yağ olduğunu belirten bir vücut kompozisyonu ölçümüdür.',
            },
            {
                title: 'Viseral (İç Organ) Yağ Düzeyi',
                msg: 'Viseral yağ, gizli obeziteyi değerlendirmek için önemli bir göstergedir.',
            },
            {
                title: 'Hücre içi su',
                msg: 'Hücre içi su: Vücut hücrelerinde bulunan vücut sıvısıdır ve protoplazmanın temel bileşenidir.',
            },
            {
                title: 'Hücre dışı su',
                msg: 'Hücre dışı su: Genellikle ekstrasellüler vücut sıvılarına atıfta bulunur, bu sıvılar kan damarları ve doku hücreleri arasındaki interstisyel sıvıyı içerir.',
            },
            {
                title: 'Metabolik Yaş',
                msg: 'Metabolik yaş, vücudun ve metabolizmayla ilgili sağlık fonksiyonlarının performansını ifade eder.',
            },
        ],
        // 节段
        segment: {
            fat: 'Bölgesel Yağ Analizi',
            muscle: 'Bölgesel Yağsız Kütle Analizi',
            right: 'Sağ',
            left: 'Sol',
            standard: 'Normal',
            lowStandard: 'Altında',
            superStandard: 'Üstünde',
            peel: '(Dara)',
        },
    },
    // 体态
    shape: {
        title: 'Vücut Değerlendirmesi Genel Bakış',
        titles: 'Postür',
        status: 'Mevcut fiziksel durumunuz',
        suggest: 'Öneri',
        possibility: ', olasılığı var',
        models: {
            front: 'Ön',
            left: 'Sol Taraf',
            right: 'Sağ Taraf',
            top: 'Arka',
            low: 'Altında',
            high: 'Üstünde',
            normal: 'Normal',
        },
        item: {
            deviate: 'Değerler',
            result: ' Açıklama',
            normal: 'Normal',
			keAbnormal: 'Olası Anormallik',
            abnormal: 'Anormal',
            head: 'İleri Baş Postürü',
            headSlant: 'Baş Eğriliği',
            roundShoulderLeft: 'Yuvarlatılmış Omuz Postürü (sol taraf)',
            roundShoulderRight: 'Yuvarlatılmış Omuz Postürü (sağ taraf)',
            highLowShoudler: 'Eşit yükseklikte olmayan omuzlar',
            pelvis: 'Pelvisin Öne/Arka Hareketi',
            leftKneeCheck: 'Sol diz değerlendirmesi',
            rightKneeCheck: 'Sağ diz değerlendirmesi',
            leg: 'Bacak Tipi',
            leftLeg: 'Sol bacak:',
            rightLeg: 'Sağ bacak:',
        },
        // 定义解读
        explain: [
            {
                title: 'İleri Baş Postürü',
                msg: 'Sol taraftaki kulak noktası ile boyun merkezi ve yan orta çizgi arasındaki çizgi arasındaki açı',
            },
            {
                title: 'Baş Eğriliği',
                msg: 'Ön başın orta noktası, boyun merkezi ve ön orta çizgi arasındaki açı',
            },
            {
                title: 'Yuvarlatılmış Omuz Postürü (sol taraf)',
                msg: 'Sırtın sol tarafındaki en yüksek noktayı bağlayan çizgi ve omuzun teğet çizgisi arasındaki açı',
            },
            {
                title: 'Yuvarlatılmış Omuz Postürü (sağ taraf)',
                msg: 'Sırtın sağ tarafındaki en yüksek noktayı bağlayan çizgi ve omuzun teğet çizgisi arasındaki açı',
            },
            {
                title: 'Eşit yükseklikte olmayan omuzlar',
                msg: 'Sırtın sol ve sağ omuz noktaları arasındaki dikey mesafe',
            },
            {
                title: 'Pelvisin Öne/Arka Hareketi',
                msg: 'Boyun merkezi noktası, kalça kemiği noktası ve sol tarafın ayak bileği noktasını bağlayan çizginin iç açısı',
            },
            {
                title: 'Sol diz değerlendirmesi',
                msg: 'Kalça kemiği noktası, diz eklemi ve sol tarafın ayak bileği noktasını bağlayan üç nokta çizgisinin iç açısı',
            },
            {
                title: 'Sağ diz değerlendirmesi',
                msg: 'Kalça kemiği noktası, diz eklemi ve sağ tarafın ayak bileği noktasını bağlayan üç nokta çizgisinin iç açısı',
            },
            {
                title: 'Bacak Tipi',
                msg: 'Sol ve sağ bacakların önündeki kalça kemiği noktası, diz eklemi ve ayak bileği noktasını bağlayan çizginin iç açısı',
            },
        ],
        exception: [
            {
                title: 'Sol Tarafa Eğimli',
                abnormal: 'sol tarafa eğimli',
            },
            {
                title: 'Sağ Tarafa Eğimli',
                abnormal: 'sağ tarafa eğimli',
            },
            {
                title: 'Sol Yüksek',
                abnormal: 'sol yüksek',
            },
            {
                title: 'Sağ Yüksek',
                abnormal: 'sağ yüksek',
            },
            {
                title: 'Arka Pelvik Eğim',
                abnormal: 'arka pelvik eğim',
            },
            {
                title: 'Ön Pelvik Eğim',
                abnormal: 'ön pelvik eğim',
            },
            {
                title: 'Sol Diz Hiper Uzatma',
                abnormal: 'sol diz hiper uzatma',
            },
            {
                title: 'Sol Diz İleri Bükülme',
                abnormal: 'sol diz ileri bükülme',
            },
            {
                title: 'Sağ Diz Hiper Uzatma',
                abnormal: 'sağ diz hiper uzatma',
            },
            {
                title: 'Sağ Diz İleri Bükülme',
                abnormal: 'sağ diz ileri bükülme',
            },
            {
                title: 'K-şekilli Bacaklar',
                abnormal: 'k-şekilli bacaklar',
            },
            {
                title: 'D-şekilli Bacaklar',
                abnormal: 'd-şekilli bacaklar',
            },
            {
                title: 'X-şekilli Bacaklar',
                abnormal: 'x-şekilli bacaklar',
            },
            {
                title: 'O-şekilli Bacaklar',
                abnormal: 'o-şekilli bacaklar',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: 'Kulaklar ile vücudun yer çekimi hattı arasındaki dikey mesafe',
            },
            {
                msg: 'İki kulağı birleştiren çizgi ile yatay düzlem arasındaki açı',
            },
            {
                msg: 'Sol akromion çıkıntısı ile yer çekimi hattı arasındaki dikey mesafe',
            },
            {
                msg: 'Sağ akromion çıkıntısı ile yer çekimi hattı arasındaki dikey mesafe',
            },
            {
                msg: 'Her iki tarafta boyun kökü ile akromion çıkıntıları arasındaki çizgilerin farkı',
            },
            {
                msg: 'Kalça eklemi ile vücudun yer çekimi hattı arasındaki dikey mesafe',
            },
            {
                msg: 'Sagital düzlemde ayak bileği eklemi, sol diz ekleminin 1 cm altındaki nokta ve kalça eklemi arasındaki açı',
            },
            {
                msg: 'Sagital düzlemde ayak bileği eklemi, sağ diz ekleminin 1 cm altındaki nokta ve kalça eklemi arasındaki açı',
            },
            {
                msg: 'Koronal düzlemde bilateral kalça eklemleri, diz eklemleri ve ayak bileği eklemleri arasındaki açı',
            },
        ],
        item: {
            pelvis: 'Anterior pelvik tilt',
        },
    },
    // 围度信息
    girth: {
        title: 'Çevreler',
        present: '(Şimdiki)',
        noHistory: 'Veri yok',
        tpl: [
            {
                title: 'Boyun çevresi',
                key: 'neckGirth',
            },
            {
                title: 'Sol üst kol',
                key: 'leftUpperArmGirth',
            },
            {
                title: 'Sağ üst kol',
                key: 'rightUpperArmGirth',
            },
            {
                title: 'Büst',
                key: 'bustGirth',
            },
            {
                title: 'Yüksek Bel',
                key: 'waistGirth',
            },
            {
                title: 'Orta Bel',
                key: 'midWaistGirth',
            },
            {
                title: 'Hipline',
                key: 'hipGirth',
            },
            {
                title: 'Sol uyluk',
                key: 'leftThighGirth',
            },
            {
                title: 'Sol uyluk ortası',
                key: 'leftMidThighGirth',
            },
            {
                title: 'Sol uyluğun minimum çevresi',
                key: 'leftMinThighGirth',
            },
            {
                title: 'Sağ uyluk çevresi',
                key: 'rightThighGirth',
            },
            {
                title: 'Sağ uyluk ortası',
                key: 'rightMidThighGirth',
            },
            {
                title: 'Sağ uyluğun minimum çevresi',
                key: 'rightMinThighGirth',
            },
            {
                title: 'Sol baldır çevresi',
                key: 'leftCalfGirth',
            },
            {
                title: 'Sağ baldır çevresi',
                key: 'rightCalfGirth',
            },
            {
                title: 'Düşük bel',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: 'Omuz Fonksiyonu',
        model: {
            val: 'Değerler',
            scope: 'Maksimum Aktivite Konumu',
            diff: 'Net',
        },
        normal: 'iyi',
        normalAll: 'Belirgin anormallikler tespit edilmedi.',
        conclusion: 'Sonuç',
        analyse: 'Analiz: ',
        suggest: 'Öneri: ',
        cause: 'Detaylar için uzmanlardan tavsiye alın.',
    },
    reportTips: {
        title: 'Raporu silmek istiyorsanız lütfen yöneticiye başvurun.',
        btnMsg: 'Tamam',
        alaryTips: 'Bu rapor yönetici tarafından silindi. Sonraki rapora atlayacak!',
    },
    saveQr: {
        title: 'Visbody 3D Vücut Tarayıcı Raporu',
        btnMsg: 'Resmi kaydetmek için resme uzun basın.',
        tips: 'Kaydedemiyorsanız, lütfen [Ayarlar]a gidin ve ilgili izinleri açın.',
    },
    share: {
        title: 'Lütfen aşağıdaki bağlantıyı kopyalayın ve paylaşın.',
        btnMsg: 'Kopyala',
    },
    ...trLocale,
    ...agrEn,
}
